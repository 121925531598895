// methods to help with converting files back and forth
export function dataUriToBlob(dataURI: string): Blob {
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
}
