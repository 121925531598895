import { EventEmitter } from 'events';
import { Service, Paginated, Params } from '@feathersjs/feathers';
import { subscribe } from '../../util/decorators/subscribe';
import { parallelQuery } from '../../util/query/parallel';
import { get, orderBy } from 'lodash';
import { getQueryKey } from './store/getQueryKey';
import { AssetGeometryType, Field } from '@wsb_dev/datafi-shared/lib/types';
import PQueue from 'p-queue';
import { debounced } from '../../util/decorators/debounced';
import { LogManager } from 'aurelia-framework';
const log = LogManager.getLogger('dfp:store');

export interface IStoreParams<T> {
    query: Record<string, any>;
    service: Service<T>;
    idField?: string;
    name?: string;
    mode: TStoreMode;
    id: number;
    expiration?: number;
    fields?: Field[];
    geometryType?: AssetGeometryType;
}

export type TStoreMode = 'paginated' | 'all'

export const storeDefaults: Partial<IStoreParams<any>> = {
    idField: 'id',
};

export interface SimpleStore<T> extends IStoreParams<T> { }

export interface IPending {
    promise: Promise<Paginated<any>>;
    queue?: PQueue;
}

@subscribe({
    events: [
        { eventEmitter: 'service', event: 'created', fn: 'onCreated' },
        { eventEmitter: 'service', event: 'patched', fn: 'onUpdated' },
        { eventEmitter: 'service', event: 'updated', fn: 'onUpdated' },
        { eventEmitter: 'service', event: 'removed', fn: 'onRemoved' },
    ],
})
export class SimpleStore<T = any> extends EventEmitter {

    /**
     * Dispatched when data changes
     * @event change
     */
    static change = 'change';

    /**
     * Active data rows
     */
    data: T[] = [];
    /**
     * total number of rows
     */
    total: number;
    /**
     * Current skipped records, if mode === 'paginated'
     */
    skip: number;
    /**
     * Current limit of records, if mode === 'paginated'
     */
    limit: number;
    /**
     * Current store fields
     */
    fields: Field[];

    /**
     * Whether store is currently loading
     */
    loading: boolean;

    /**
     * Service name to query (ex: api/v1/projects)
     */
    service: Service<T>;
    /**
     * Service parameters
     */
    query: Record<string, any>;
    /**
     * Used to prevent multiple refreshes from triggering multiple queries
     */
    previousQueryKey: string;

    /**
     * name of store for persistence
     */
    name: string;
    /**
     * Store ID
     */
    id: number;
    /**
     * Current mode for store. If paginated, only a subset of records are fetched
     */
    mode: TStoreMode = 'paginated';

    geometryType: AssetGeometryType;

    defaultQuery: Record<string, any>;

    /**
     * Pending promise/queue data
     */
    private pending: IPending;

    constructor() {
        super();
    }

    onCreated = (item: T) => {
        this.data = [
            ...this.data,
            item,
        ];
        this.emit(SimpleStore.change, this.data);
    }

    onUpdated(item: T): void {
        let itemToUpdate = this.data.find((i) => get(item, this.idField) === get(i, this.idField));
        if (itemToUpdate) {
            itemToUpdate = {
                ...itemToUpdate,
                ...item,
            };
        }
        this.emit(SimpleStore.change, this.data);
    }

    onRemoved = (item: T) => {
        const id = get(item, this.idField);
        this.data = this.data.filter((row) => get(row, this.idField) !== get(item, this.idField));
        this.emit(SimpleStore.change, this.data);
    }

    setup(params: IStoreParams<T>): SimpleStore<T> {
        Object.assign(this, storeDefaults, params);
        this.attached();
        this.defaultQuery = {...this.query};
        return this;
    }

    destroy(): SimpleStore {
        this.data = null;
        this.detached();
        return this;
    }

    // empty methods for event hooks
    attached() { return this; }
    detached() { return this; }

    setQuery(query: Record<string, any>, mode?: TStoreMode) {
        this.loading = true;
        this.mode = mode || this.mode;
        this.query = query;
        return this.refresh();
    }

    resetQuery() {
        this.setQuery(this.defaultQuery);
    }

    @debounced(100)
    async refresh(): Promise<SimpleStore> {
        const query = this.query;
        const newQueryKey = getQueryKey(query);
        log.debug(`[store]<${this.id}>: updating query: ${newQueryKey}`);
        if (this.previousQueryKey === newQueryKey) {
            log.debug(`[store]<${this.id}>: skipping query ${this.previousQueryKey}`);
            return this;
        }
        this.previousQueryKey = newQueryKey;
        return this.updateData();
    }

    async updateData(): Promise<SimpleStore> {
        if (this.pending) {
            log.warn(`[store]<${this.id}>: cancelling existing queue`);
                this.pending.queue?.clear();
        }

        const query = {
            ...this.query,
            // all services support 5000 records now
            $limit: 1000,
        };

        log.debug(`[store]<${this.id}>: executing query`, query);
        this.loading = true;

        if (this.mode === 'paginated') {
            const promise = this.service.find({ query }) as Promise<Paginated<any>>;
            this.pending = { promise };
        } else {

            const queue = await parallelQuery(this.service, query);
            let result = [];
            queue.on('completed', (data) => result = [...result, ...data]);
            queue.start();

            const promise = queue.onIdle().then(() => {
                return { data: result, total: result.length, limit: 0, skip: 0 };
            });
            this.pending = { queue, promise };
        }

        const result = await this.pending.promise;

        const { limit, data, total, skip } = result;
        Object.assign(this, { limit, data, total, skip, loading: false, pending: null });
        this.emit(SimpleStore.change, this.data);
        log.debug(`[store]<${this.id}>: query complete`, result);

        return this;
    }

    // fake service methods
    find(params: Params): Promise<Paginated<any>> {
        const perPage = params.query.$limit || 10;
        const offset = params.query.$skip || params.query.$offset || 0;
        if (params.query.$sort) {
            const sortField = Object.keys(params.query.$sort).find((key) => params.query.$sort[key] !== undefined);
            const sortOrder = params.query.$sort[sortField] === 1 ? 'asc' : 'desc';
            // Use lodash orderBy to sort the data
            this.data = orderBy(this.data, [sortField], [sortOrder]);
        }
        const items = this.data.slice(offset, offset + perPage);

        return Promise.resolve({
            total: this.total,
            data: items,
            skip: offset,
            limit: perPage,
        });
    }

}
