export class JsonValueConverter {
    toView(value) {
        return JSON.stringify(value, undefined, 4);
    }

    fromView(value) {
        value = value || '{}';
        return JSON.parse(value);
    }
}
