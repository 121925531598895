import { EventAggregator } from 'aurelia-event-aggregator';
import { DetailedSurveyItem } from '@wsb_dev/datafi-shared/lib/types/SurveyItem';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { GenerateKoboURLData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import get from 'lodash.get';
import defaults from 'lodash.defaults';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { AppConfig } from '../../../services/util/AppConfig';

const log = LogManager.getLogger('dfp:kobo');

interface URLResult {
    url: string;
    name?: string;
    detail?: string;
}

@autoinject
export default class GenerateKoboURLTask {
    constructor(
        private ea: EventAggregator,
        private api: DatafiProAPI,
        private config: AppConfig,
        private program: ActiveProgram,
    ) {}

    async execute(data: GenerateKoboURLData): Promise<{ url: string }> {
        const target = data.target[0] || {};

        const programSurvey = await this.api.programSurveys.get(data.surveyId);
        data.survey = programSurvey;

        const urlResult = await (
            data.edit ?
                this.getEditUrl(target, data) :
                this.getNewSurveyUrl(target, data)
        );

        if (urlResult.detail) {
            throw new Error(urlResult.detail);
        }

        if(data.tab){
            window.open(urlResult.url);
        }

        //TODO remove data.open
        else if (data.iframe || data.open) {
            if(data.open){
                log.warn('GenerateKoboUrl `open` is deprecated. Use `iframe` instead');
            }
            // window.location.href = urlResult.url;
            this.ea.publish('iframe', {
                src: urlResult.url,
                title: urlResult.name,
            });
        }
        return urlResult;
    }

    async getEditUrl(target: Record<string, any>, data: GenerateKoboURLData): Promise<URLResult> {

        const id = target[data.targetIdField] || target.id || target._id;
        if (!id) {
            throw new Error('Row is missing unique ID value');
        }

        const surveyId = data.surveyId;

        if (!surveyId) {
            throw new Error('Survey could not be found');
        }

        let urlResult;

        if(data.survey.survey_type === 'dfp-form'){
            urlResult = await this.api.formUrls.create({
                fileId: data.survey.survey_id,
                instanceId: id,
                type: 'edit_url',
            });

        } else {
            urlResult = await this.api.surveys.get(id, {
                query: {
                    $edit: true,
                    survey_id: surveyId,
                },
            });
        }

        return {
            ...urlResult,
            name: `Edit form: ${target.projectname || ''} (${id})`,
        };
    }

    async getNewSurveyUrl(target: Record<string, any>, data: GenerateKoboURLData): Promise<URLResult>{

        const { targetFields, actionFields } = defaults(data, {
            program: this.program,
            targetFields: data.survey.survey_type === 'dfp-form' ?
                // with new datafi pro forms field is coded as `project_id`
                { id: 'project_id' } :
                // older kobo forms we had it as projectid
                {id: 'projectid'},
            actionFields: {
                'survey.id': 'survey_id',
                'program.id': 'program_id',
                'auth.user.email': 'email',
                'auth.user.fullname': 'fullname',
                'auth.user.id': 'userId',
                'auth.user.username': 'username',
            },
        } as Partial<GenerateKoboURLData>);

        let baseUrl;
        const programSurvey = await this.api.programSurveys.get(data.surveyId);
        if(programSurvey.survey_type === 'dfp-form'){
            const result = await this.api.formUrls.create({
                fileId: programSurvey.survey_id,
                type: data.urlType || 'single_url',
            });
            baseUrl = result.url;
        } else {
            const survey = await this.api.surveys.get(programSurvey.id, {query: {$refresh: true }}) as DetailedSurveyItem;
            baseUrl = survey.deployment__links[data.urlType || 'single_url'];
        }
        if(!baseUrl){
            throw new Error('Form URL could not be found');
        }
        const surveyUrl = baseUrl + '?' +

            // get fields from app context
            this.getUrlFragment(data, actionFields) +
            this.getUrlFragment(target, targetFields);

        return {
            url: surveyUrl, //encodeURI(surveyUrl),
            name: `New Form: ${data.target?.[0]?.project_name || 'Blank'}`,
        };
    }

    getUrlFragment(context: any, fields: Record<string, string>) {
        let url = '';
        if (fields) {
            delete fields.__expanded;
            Object.keys(fields).forEach((field) => {
                const fieldName = fields[field];
                const formFieldName = fieldName.indexOf('.') > -1 ?
                    fieldName.split('.')[1] :
                    fieldName;
                const value = get(context, field);

                if (value) {
                    url += `&d[${formFieldName}]=${encodeURIComponent(value)}`;
                } else {
                    log.warn(`Target field ${field} was undefined`);
                }
            });
        }
        return url;
    }
}
