import { FrameworkConfiguration, PLATFORM } from 'aurelia-framework';
import './validation/matchesProperty';
import './validation/json';

export function configure(config: FrameworkConfiguration): void {
    config.globalResources([
        PLATFORM.moduleName('resources/value-converters/array'),
        PLATFORM.moduleName('resources/value-converters/json'),
        PLATFORM.moduleName('resources/value-converters/date-format'),
        PLATFORM.moduleName('resources/value-converters/file-icon'),
        PLATFORM.moduleName('resources/value-converters/has-permission'),
        PLATFORM.moduleName('resources/value-converters/round'),
        PLATFORM.moduleName('resources/value-converters/group'),
        PLATFORM.moduleName('resources/value-converters/title'),
        PLATFORM.moduleName('resources/value-converters/object-key'),
        PLATFORM.moduleName('resources/value-converters/keys'),
        PLATFORM.moduleName('resources/value-converters/number'),
        PLATFORM.moduleName('resources/value-converters/unknown'),
        PLATFORM.moduleName('resources/value-converters/default'),
        PLATFORM.moduleName('resources/value-converters/job-status'),
        PLATFORM.moduleName('resources/value-converters/purify-html'),

        PLATFORM.moduleName('resources/binding-behaviors/stop-propagation'),

    ]);
}
