import { HookContext } from '@feathersjs/feathers';

/**
 * Takes a result object and looks for an Array-like object.
 * If this is found, the result is converted to an array.
 * This happens in some cases where arrays in multi-creates get converted to
 * ```
 * {
 *      "0": "...",
 *      "1": "...",
 *      ...
 * }
 * ```
 * @param context Current hook context
 * @returns Hook context, modified if necessary
 */
export function convertResult(context: HookContext): HookContext {
    // handle multi creates
    if (context.result.hasOwnProperty('0')) {
        context.result = Object.keys(context.result).map(
            (k) => context.result[k],
        );
    }

    return context;
}
