import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { ExecuteJobData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import { inject } from 'aurelia-framework';
import { DatafiProAPI } from '../../api/DatafiProAPI';
import { getJobResult } from '../../api/util/jobs/getJobresult';

@inject(DatafiProAPI)
export default class ExecuteJobTask {

    constructor(
        private api: DatafiProAPI,
    ) { }
    execute(data: ExecuteJobData): Promise<APIFile> {

        return this.api.jobs.create({
            name: data.name,
            data: data.data,
        }, {query: data.query})
            .then((job) => getJobResult(this.api.jobs, job))
            .then((job) => job.result);
    }
}
