import { GenerateCSVData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import {Task} from './Task';
import papa from 'papaparse';
import get from 'lodash.get';
import { Field } from '@wsb_dev/datafi-shared/lib/types/Field';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { DateValueConverter } from '../../../resources/value-converters/date-format';
import { LogManager } from 'aurelia-framework';

const log = LogManager.getLogger('dfp:actions');

const excludeFieldRegex = /(meta|uuid)/gi;
export default class GenerateCSVTask implements Task {

    async execute(data: GenerateCSVData) {

        // get the csv data
        const fields: Field[] = getFields(data.fields) || this.getFields(data.target, data.rowsField);
        const rows = this.getRows(data.target, data.rowsField, fields);
        const labels = fields.map((f) => f.label);

        // export blob
        const result = papa.unparse({
            fields: labels,
            data: rows,
        });
        log.debug('csv result', result);
        const blob = new Blob([result], { type: 'text/csv;charset=utf-8;' });

        return { file: blob };
    }

    getFields(target: any[], rowsField?: string):Field[] {
        // get fields from action data or the first row
        if (rowsField) {
            target = target.find((item) => !!item[rowsField])?.[rowsField];
        }
        const firstRow = (Array.isArray(target) ?
            target[0] :
            target) || {};

        const fields = Object.keys(firstRow)
            .filter((field) => {
                // exclude if string has _ or kobo / in it
                return !(
                    field.startsWith('_') ||
                    excludeFieldRegex.test(field)
                );
            });

        return getFields(fields);
    }

    getRowValue(item: Record<string, any>, field: Field) {
        const rawValue = get(item, field.name);
        if(field.type === 'date'){
            const format = field.format;
            return new DateValueConverter().toView(rawValue, format);
        }

        return rawValue;
    }

    getRows(data: any[], rowsField: string, fields: Field[]): any[][] {

        // build the csv
        /**
         * data: [{
         *      id: 1,
         *      items: [{ }]
         * }]
         * rowsField: 'items'
         */
        const items: Record<string, any>[] = data.reduce((prev: any[], current: Record<string, any>) => {

            // first get child rows
            let childRows = rowsField ?
                get(current, rowsField) :
                [current];

            if (!childRows) childRows = [];
            if(!Array.isArray(childRows)) childRows = [childRows];

            // convert them to row items
            const rowItems = childRows.map((item: Record<string, any>) => {
                const result = {
                    ...current,
                    ...item,
                };
                // backwards compatibility
                result.$root = current;

                return result;

            });

            // reduce the result to a single array
            return prev.concat(rowItems);
        }, []);

        log.debug('[GenerateCSV]: ', items, fields);

        // iterate through each item
        // build a row from each field
        return items.map((row) => fields.map((field) => this.getRowValue(row, field)));
    }
}
