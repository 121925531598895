
export function convertInt(...fields: string[]) {
    return function (context) {
        fields.forEach((field) => {
            if(typeof context.data[field] === 'undefined'){
                return;
            }
            context.data[field] = parseInt(context.data[field], 10);
        });
    };
}
