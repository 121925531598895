import { BrowserStorage } from './BrowserStorage';
import { LogManager, autoinject } from 'aurelia-framework';
import { debounced } from '../../util/decorators/debounced';
const log = LogManager.getLogger('dfp:programs');

export interface ProgramActivity {
    id: number,
    title: string
}
@autoinject

export class RecentPrograms {
    activity: ProgramActivity[];
    constructor(
        private storage: BrowserStorage,
    ) {
        this.getBrowserStorage().then((activity) => {
            this.activity = activity.data;
        });
    }

    @debounced(500)
    async manageActivity(newActivity?: ProgramActivity) {
        if (!this.activity) {
            this.activity = [];
        }
        const hasId = this.activity.map((obj) => obj).find((item: ProgramActivity) => item.id === newActivity?.id);
        if (!hasId) {
            this.activity.unshift(newActivity);
        } else {
            const index = this.activity.findIndex((item) => item.id === newActivity.id);
            this.activity.splice(index, 1);
            this.activity.unshift(newActivity);
        }

        if (this.activity.length > 4) {
            this.activity.pop();
        }
        // await this.getPermissions();

        this.storage.create({
            id: 'program_activity',
            data: this.activity as ProgramActivity[],
        });
    }

    async getBrowserStorage() {
        return await this.storage.get('program_activity')
            .then((activity) => {
                return this.storage.create({
                    id: 'program_activity',
                    data: activity.data as ProgramActivity,
                });
            }).catch((e) => {
                log.debug(e);
                return this.storage.create({
                    id: 'program_activity',
                    data: [],
                });
            });
    }

    clear() {
        this.storage.remove('program_activity');
        this.activity = [];
    }
}
