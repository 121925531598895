import { HookContext, Service } from '@feathersjs/feathers';
import {
    APIFile,
    DetailedSurveyItem,
    AppEvent,
    GeocodeResult,
    Job,
    Organization,
    Program,
    ProgramAsset,
    ProgramAssetType,
    ProgramConfig,
    ProgramSecret,
    ProgramSurvey,
    ProgramUserRole,
    Project,
    ProjectFile,
    Role,
    Secret,
    Token,
    User,
    UserRole,
} from '@wsb_dev/datafi-shared/lib/types';
import { FormSubmission } from '@wsb_dev/datafi-shared/lib/types/FormSubmission';
import { IFormUrlData } from '@wsb_dev/datafi-shared/lib/types/FormUrl';
import { ProgramWebhook } from '@wsb_dev/datafi-shared/lib/types/ProgramWebhook';
import { Container, autoinject } from 'aurelia-dependency-injection';
import { AppConfig } from '../util/AppConfig';
import { FeathersApplication } from '../../types/Application';
import { ValidatedProject } from '../../types/Project';
import { AuthenticationService } from './authentication';
import { FileService } from './file';
import { convertInt } from './hooks/convertInt';
import { omitEmpty } from './hooks/omitEmpty';
import { omitGeomIfBinary } from './hooks/omitGeomIfBinary';
import { omitProps } from './hooks/omitProps';
import { parseId } from './hooks/parseId';
import { BrowserStorage } from '../util/BrowserStorage';
import { StorageItem } from '../util/_StorageBase';
import { DB } from '../util/DB';
import { OfflineService } from './OfflineService';
import log from '../util/Log';

@autoinject
export class DatafiProAPI {
    public events: Service<AppEvent>;
    public files: Service<APIFile>;
    public formSubmissions: Service<FormSubmission>;
    public formUrls: Service<IFormUrlData>;
    public geocode: Service<GeocodeResult>;
    public jobs: Service<Job<any>>;
    public organizations: Service<Organization>
    public programAssets: Service<ProgramAsset>;
    public programAssetTypes: Service<ProgramAssetType>;
    public programConfigs: Service<ProgramConfig>;
    public programs: Service<Program>;
    public programSecrets: Service<ProgramSecret>;
    public programSurveys: Service<ProgramSurvey>;
    public programUsersRoles: Service<ProgramUserRole>;
    public programWebhooks: Service<ProgramWebhook>;
    public projectFiles: Service<ProjectFile>;
    public projects: Service<Project>;
    public roles: Service<Role>;
    public secrets: Service<Secret>;
    public surveys: Service<ProgramSurvey|DetailedSurveyItem>;
    public surveysMedia: Service<APIFile>;
    public tokens: Service<Token>;
    public users: Service<User>;
    public usersRoles: Service<UserRole>;

    public connected: boolean;

    constructor(
        public app: FeathersApplication,
        public db: DB,
        public auth: AuthenticationService,
        private config: AppConfig,
        private browserStorage: BrowserStorage,
        private container: Container,
    ) {

        app.io.on('connect', () => this.handleConnectionStatus(true));
        app.io.on('disconnect', () => this.handleConnectionStatus(false));
        this.handleConnectionStatus(app.io.connected);

        this.events = app.service('api/v1/events');
        this.files = this.container.get(FileService);
        this.formSubmissions = this.container.get(OfflineService).createService(this, 'api/v1/form-submissions') as any;
        this.organizations = app.service('api/v1/organizations');
        this.programAssets = app.service('api/v1/programs-assets');
        this.programAssetTypes = app.service('api/v1/programs-asset-types');
        this.programConfigs = app.service('api/v1/programs-configs');
        this.programs = this.container.get(OfflineService).createService(this, 'api/v1/programs') as any;
        {
            const omitRelations = omitProps(
                ['data'],
                ['assetTypes', 'roles', 'configs', 'surveys', 'templateScope'],
            );
            this.programs.hooks({
                before: {
                    create: [omitRelations],
                    patch: [omitRelations],
                    update: [omitRelations],
                },
            });
        }
        this.programSurveys = this.container.get(OfflineService).createService(this, 'api/v1/programs-surveys') as any;
        {
            const omitRelations = omitProps(['data'], ['survey']);
            const convertArea = convertInt('groupByArea');
            this.programSurveys.hooks({
                before: {
                    create: [omitRelations, convertArea],
                    patch: [omitRelations, convertArea],
                    update: [omitRelations, convertArea],
                },
            });
        }
        this.projectFiles = this.container.get(OfflineService).createService(this, 'api/v1/projects-files') as any;
        {
            const omitGeom = omitGeomIfBinary('geom');
            this.projectFiles.hooks({
                before: {
                    patch: [omitGeom],
                    update: [omitGeom],
                },
            });
        }
        this.projects = this.container.get(OfflineService).createService(this, 'api/v1/projects') as any;
        this.projects.hooks({
            after: {
                update: [parseId],
                patch: [parseId],
                find: [
                    async (context: HookContext) => {
                        if (context.params.query.program_id) {
                            const programId = context.params.query.program_id;
                            const pinnedId = `pinned_projects-${programId}`;
                            await this.browserStorage.get(pinnedId).then((pins) => {
                                if (pins.data && context) {
                                    pins.data.forEach((pinId: number) => {
                                        const pinnedProjects = context.result.data.find((project: ValidatedProject) => project.id === pinId);
                                        if (pinnedProjects) {
                                            pinnedProjects._pinned = true;
                                        }
                                    });
                                }
                            }).catch((e) => ({ id: pinnedId, data: {} } as StorageItem));
                        }
                    },
                ],

            },
        });

        this.formUrls = app.service('api/v1/form-urls');
        this.geocode = app.service('api/v1/geocode');
        this.jobs = app.service('api/v1/jobs');
        this.jobs.hooks({
            after: {
                update: [parseId],
                patch: [parseId],
            },
        });
        this.programSecrets = app.service('api/v1/programs-secrets');
        this.programUsersRoles = app.service('api/v1/programs-users-roles');
        this.programWebhooks = app.service('api/v1/programs-webhooks');
        this.roles = app.service('api/v1/roles');
        this.roles.hooks({
            after: {
                update: [parseId],
                patch: [parseId],
            },
        });

        this.secrets = app.service('api/v1/secrets');
        {
            const omitRelations = omitProps(['data'], ['user']);
            this.secrets.hooks({
                before: {
                    patch: [omitRelations],
                    create: [omitRelations],
                    update: [omitRelations],
                },
            });
        }
        this.tokens = app.service('api/v1/tokens');
        this.usersRoles = app.service('api/v1/users-roles');
        {
            const omitRole = omitProps(['data'], ['role']);
            this.usersRoles.hooks({
                before: {
                    create: [omitRole],
                    patch: [omitRole],
                    update: [omitRole],
                },
                after: {
                    update: [parseId],
                    patch: [parseId],
                },
            });
        }

        this.users = app.service('api/v1/users');
        {
            const omitRoles = omitProps(['data'], ['roles', 'programRoles']);
            const omitWhenEmpty = omitEmpty('password');
            this.users.hooks({
                before: {
                    create: [omitRoles],
                    patch: [omitRoles, omitWhenEmpty],
                    update: [omitRoles],
                },
                after: {
                    update: [parseId],
                    patch: [parseId],
                },
            });
        }
    }

    handleConnectionStatus(online: boolean){
        this.connected = online;
        if(online){
            this.auth.reAuthenticate(true);
        }
        log.info(online ? 'Application is now online' : 'Application is now offline');
    }
}
