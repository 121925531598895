import { HookContext } from '@feathersjs/feathers';
import omit from 'lodash.omit';
export function omitProps(contextProps: string[], propsToOmit: string[]) {

    return function omitHook(context: HookContext) {
        contextProps.forEach((prop) => {
            if (!context[prop]) {
                return;
            }
            context[prop] = omit(context[prop], propsToOmit);
        });

        return context;
    };
}
