import { hasValue } from '@wsb_dev/datafi-shared/lib/util/types/hasValue';
import { StorageBase, StorageItem } from './_StorageBase';
import dayjs from 'dayjs';
import { LogManager } from 'aurelia-framework';

const log = LogManager.getLogger('dfp:storage');

export class BrowserStorage extends StorageBase {
    prefix = 'dfp-';
    timeout;
    async create(item: StorageItem): Promise<StorageItem> {
        log.debug('create', item);
        this._setItem(item);
        return item;
    }

    async patch(id: string, data: Record<string, any>) {
        log.debug('patch', id, data);
        const existing = await this.get(id)
            .catch((e) => {
                return {
                    id: id,
                    data: {},
                };
            });

        existing.data = {
            ...existing.data,
            ...data,
        };
        this._setItem(existing);
        return existing;
    }

    async get(id: string | number): Promise<StorageItem> {
        log.debug('get:', id);

        const data = this._getItem(id);
        if (!hasValue(data)) {
            throw new Error('Item not found: ' + id);
        }

        let result: StorageItem;
        try {
            result = JSON.parse(data);
        } catch (e) {
            result = { id, data: {} };
        }

        return result;
    }

    async remove(id: string | number): Promise<StorageItem> {
        log.debug('remove:', id);
        const item = await this.get(id);
        localStorage.removeItem(this._getKey(id));
        return item;
    }

    private _getKey(id: string | number) {
        return '' + this.prefix + id;
    }

    private _getItem(id: string | number) {
        this._clean();
        return localStorage.getItem(this._getKey(id));
    }

    private _setItem(item: StorageItem) {
        this._clean();

        item.id = item.id || this.generateId();
        item.created = new Date().getTime();

        let json: string;
        try {
            json = JSON.stringify(item);
        } catch (e) {
            log.error(e);
            return;
        }

        try {
            localStorage.setItem(this._getKey(item.id), json);
        } catch (e) {
            // error is likely due to low browser storage space
            log.error(e);
            const today = dayjs();
            this._clean(today.subtract(7, 'days').toDate());
        }
    }

    private _clean(forceCleanBefore?: Date) {
        if (this.timeout) {
            return;
        }

        this.timeout = setTimeout(() => {
            log.debug('clean:start');

            const keys = Object.keys(localStorage)
                .filter((key) => key.startsWith(this.prefix));

            for (const key of keys) {
                const json = localStorage.getItem(key);
                try {
                    const data = JSON.parse(json) as StorageItem;

                    const created = data.created ? new Date(data.created) : null;
                    const expires = data.expires ? new Date(data.expires) : null;

                    if (expires || forceCleanBefore) {

                        if (forceCleanBefore && (!created || created.getTime() < forceCleanBefore.getTime())) {
                            log.debug('clean:forced', data);
                            localStorage.removeItem(key);
                        }

                        if (expires.getTime() < new Date().getTime()) {
                            log.debug('clean:expired', data);
                            localStorage.removeItem(key);
                        }
                    }
                } catch (e) {
                    log.debug(e);
                }
            }
            this.timeout = null;
            log.debug('clean:done');
        }, 1000);
    }
}
