import { ServiceMethods } from '@feathersjs/feathers';

export interface StorageItem {
    id?: string|number;
    created?: number;
    expires?: number;
    data: any;
}

export class StorageBase implements Partial<ServiceMethods<StorageItem>> {
    currentIndex: number;
    browserStorageKey = 's'

    constructor() {
        this.get(`${this.browserStorageKey}_idx`).catch(() => {
            return this.create({
                id: `${this.browserStorageKey}_idx`,
                data: 0,
            });
        }).then((item) => {
            this.currentIndex = parseInt(item.data);
        });
    }
    generateId(): string {
        return `${this.browserStorageKey}_item_${this.currentIndex++}`;
    }
    async get(id: string): Promise<any> {
        throw new Error('Not implemented');
    }
    async create(item: StorageItem): Promise<any> {
        throw new Error('Not implemented');
    }
}
