export function getQueryKey(query: Record<string, any>): string {
    // standardize query and compare it to the previous query
    const key = Object.keys(query)
        .filter((key) => {
            return !['$limit', '$skip', '$sort'].includes(key) &&
                    typeof query[key] !== 'undefined';
        })
        .sort()
        .map((k) => `${k}:${JSON.stringify(query[k])}`)
        .join(',');

    return key;

}
