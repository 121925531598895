import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { dataUriToBlob } from './dataUriToBlob';
import readXlsxFile from 'read-excel-file';
import { LogManager } from 'aurelia-framework';

const log = LogManager.getLogger('dfp:xcel');

export function getExcelJson(file: APIFile, opt): Promise<Record<string, any>[]>{
    const blob = dataUriToBlob(file.uri as string);
    return readXlsxFile(blob as File, opt).then((result) => {
        if(result.errors?.length){
            log.error('xcel errors', ...result.errors);
        }
        return result.rows;
    });
}
