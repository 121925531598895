import { PopulateActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import { PopulateDataParam } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import {Task} from './Task';
import { Service } from '@feathersjs/feathers';
import { isPrimitive } from '../../../util/objects/isPrimitive';
import { LogManager, inject } from 'aurelia-framework';
import { DatafiProAPI } from '../../api/DatafiProAPI';
import set from 'lodash.set';
const log = LogManager.getLogger('dfp:actions');

@inject(
    DatafiProAPI,
)
export default class PopulateTask implements Task {
    constructor(private api: DatafiProAPI) { }
    execute(params: PopulateActionData) {
        const { target, populate } = params;
        return this.populateObjects(target, populate);
    }

    async populateObjects(objects: any[], queries: PopulateDataParam[] = []): Promise<any[]> {
        return Promise.all(objects.map((obj) => this.populateObject(obj, queries)));
    }

    populateObject<T>(obj: any, populateParams: PopulateDataParam[]): Promise<T[]> {
        return Promise.all(populateParams.map((params: PopulateDataParam) => {

            // if key is present already, just return that data structure populated
            if (obj[params.nameAs]) {
                return this.populateObjects(obj[params.nameAs], params.populate);
            }

            if(!params.service){
                return [];
            }

            // call the service method required with the required arguments
            const service = this.api.app.service(params.service) as Service<T>;
            const serviceMethod = params.serviceMethod || 'find';
            const serviceArgs = this.getArguments(serviceMethod, obj, params);

            // return the result and populate sub-queries
            return service[serviceMethod](...serviceArgs).then((result: any) => {

                // get data from paginated and non paginated results
                // check for primitive types
                let data = result.data ? result.data : result;
                if (isPrimitive(data)) {
                    return data;
                }

                // convert to array for consistency
                if(!data){
                    data = [];
                } else if(!Array.isArray(data)){
                    data = [data];
                }
                return this.populateObjects(data, params.populate);
            })
                .catch((e) => {
                    log.error(e);
                    return obj;
                });

        })).then((resultQueries) => {
            const resultData = {
                ...obj,
            };
            resultQueries.forEach((result, index) => {
                const param = populateParams[index];
                resultData[param.nameAs || param.service] = result;
            });

            return resultData;
        });
    }

    getArguments(serviceMethod: string, object: any, params: PopulateDataParam) {
        return serviceMethod === 'find' ?
            [{
                ...params.params,
                query: {
                    ...params.params?.query,
                    ...set(params.params?.query, params.childField, object[params.parentField]),
                },
            }] :
            [
                object[params.parentField],
                { ...params.params },
            ];
    }
}
