import { Program } from '@wsb_dev/datafi-shared/lib/types/Program';
import { PermissionType } from '@wsb_dev/datafi-shared/lib/types/Roles';
import { User } from '@wsb_dev/datafi-shared/lib/types/Users';
import { userHasPermission } from '@wsb_dev/datafi-shared/lib/util/users/userHasPermission';
import { LogManager } from 'aurelia-framework';

const log = LogManager.getLogger('dfp:permission');

export class HasPermissionValueConverter {
    toView(...args) {
        let program: null | Program | number, user: User, service: string, perm: PermissionType;

        // handle arguments - we can accept the following:
        // - user, program, service, perm -> program roles
        // - user, service, perm  -> user roles
        if (args.length === 4) {
            [user, program, service, perm] = args;
        } else {
            [user, service, perm] = args;
        }

        if (!user) {
            log.debug('user not provided', args);
            return false;
        }

        const programId: number = typeof program === 'number' ?
            program :
            program?.id;

        const returnValue = programId ?
            userHasPermission(user, service, perm, programId) :
            userHasPermission(user, service, perm);

        log.debug(`permission valid: ${returnValue}`, args);
        return returnValue;
    }
}
