import { AuthCreated } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { BrowserStorage } from './BrowserStorage';

import { IToggle, UnleashClient } from 'unleash-proxy-client';
import { AppConfig } from './AppConfig';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { subscribe } from '../../util/decorators/subscribe';
import { DFPAuthResult } from '../../types/Authentication';
import { EventEmitter } from 'events';

@subscribe({
    events: [
        { eventEmitter: 'eaw', event: AuthCreated, fn: 'updateUnleashUser' },
        { eventEmitter: 'client', event: 'update', fn: 'onUpdate' },
    ],
})
@autoinject
/**
 * A unleash api feature flag service that enables toggling features in real time applications
 * Usage:
 * 1. Add a feature flag property in this class - set it to false
 * 2. Create a new feature flag that matches the name of the property you set in gitlab for this repository
 *
 */
export class FeatureFlagsService extends EventEmitter {

    client: UnleashClient;

    browser_image_cache = true;
    offline_storage_enabled = true;
    login_helpdesk_link = false;

    constructor(
        private storage: BrowserStorage,
        private config: AppConfig,
        private eaw: EventAggregatorWrapper) {
        super();

        if (!this.config.UNLEASH_API_URL) {
            return;
        }

        this.client = new UnleashClient({
            url: this.config.UNLEASH_API_URL,
            clientKey: this.config.UNLEASH_CLIENT_KEY,
            appName: this.config.UNLEASH_ENVIRONMENT,
            environment: this.config.UNLEASH_ENVIRONMENT,
        });

        this.attached();
    }

    attached() { return; }
    detached() { return; }

    onUpdate() {
        const toggles = this.client.getAllToggles();
        this.updateFlags(toggles);
        this.emit('update', this);
    }

    updateFlags(toggles: IToggle[]) {
        Object.keys(this).forEach((key) => {
            if (typeof this[key] === 'boolean') {
                const toggle = toggles.find((toggle) => toggle.name === key);

                this[key] = !!toggle?.enabled;
            }
        });
    }

    updateUnleashUser(result: { auth: DFPAuthResult }) {
        this.client.updateContext({ userId: result.auth.user.username });
        this.client.start().then(() => this.updateFlags(this.client.getAllToggles()));
    }

    async startUnleash() {
        await this.client.start().then(() => this.updateFlags(this.client.getAllToggles()));
    }

    isEnabled(flag: string) {
        return this.client.isEnabled(flag);
    }
}
