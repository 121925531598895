import { LogManager } from 'aurelia-framework';
import { reject } from 'promise-polyfill';
import { Id, Paginated, Params } from '@feathersjs/feathers';
import sift from 'sift';
import {sorter} from '@feathersjs/adapter-commons';

export type DataStoreOptions = 'formSubmissions' | 'projects' | 'programs' | 'programsSurveys' | 'settings';
export type DataStatusOptions = 'draft' | 'pending' | 'saved';
export interface IDBRecord {
    id?: Id;
    __status?: DataStatusOptions;
    __dateStored?: string;
    [key: string]: unknown;
}

export interface SettingsRecord extends IDBRecord {
    nextId: number;
}

export interface IDBParams extends Params {
    dataStoreName: DataStoreOptions;
}

const log = LogManager.getLogger('dfp:indexdb');

const defaultIndexes = [
    '__status',
    '__dateStored',
];

export class DB {

    db: IDBDatabase;
    connectionPromise: Promise<IDBDatabase>;

    private __version = 1;

    constructor(){
        this.connectionPromise = this.connect();
    }

    connect(){
        log.info('connecting to db');
        return new Promise<IDBDatabase>((resolve, reject) => {
            const DBOpenRequest = window.indexedDB.open('dfp', this.__version);

            DBOpenRequest.addEventListener('success', (event) => {
                this.db = DBOpenRequest.result;
                resolve(DBOpenRequest.result);
            });

            DBOpenRequest.addEventListener('error', (event) => {
                reject(DBOpenRequest.error);
            });

            DBOpenRequest.addEventListener('upgradeneeded',  (event: any) => {
                this.upgrade(event.target.result);
            });
        });
    }

    disconnect(): Promise<void>{
        log.info('disconnecting db');
        return new Promise((resolve) => {
            const res = this.db.close();
            this.db.addEventListener('close', (event) => {
                resolve();
            });
            this.db.addEventListener('error', (event) => {
                log.error('error on close', res);
            });
        });
    }

    async find(params: IDBParams): Promise<Paginated<IDBRecord>>{
        log.debug('find fetching records', params.dataStoreName);
        const store = this.getDataStore(params.dataStoreName);
        const req = store.getAll();

        return new Promise((resolve, reject) => {
            req.addEventListener('success', () => {
                log.debug('find success', params.dataStoreName, req.result);

                let values = this.filter(req.result as Record<string, any>[], params.query);
                params.query.$sort ?
                    values.sort(sorter(params.query.$sort)) :
                    values;

                const limit = params.query.$limit || 10;
                const start = params.query.$offset || params.query.$skip || 0;
                const end = start + limit;
                const total = values.length;
                values = values.slice(start, end);

                resolve({
                    data: values,
                    limit: limit,
                    skip: start,
                    total: total,
                });
            });
        });
    }

    async get(id: Id, params: IDBParams): Promise<IDBRecord>{
        log.debug('get fetching record', params.dataStoreName, id);
        const store = await this.getDataStore(params.dataStoreName);
        const req = store.get(id);

        return new Promise((resolve, reject) => {
            req.addEventListener('success', (event) => {
                log.debug('get result', req.result);

                const filtered = this.filter([req.result], params.query);
                if(!filtered.length){
                    throw new Error('item with matching id was not found');
                }
                if(filtered.length > 1){
                    throw new Error('more than one item was found');
                }

                resolve(filtered[0]);
            });
            req.addEventListener('error', (error) => {
                log.error('error on get', req.error);
                reject(req.error);
            });
        });

    }

    async create(record: IDBRecord|IDBRecord[], params: IDBParams){
        if(Array.isArray(record)){
            return Promise.all(record.map((i) => this.create(record, params)));
        }

        log.debug(`creating record:${params.dataStoreName}`, record);
        // order matters a LOT here - the next line must come before
        // the transaction creation because the next line may invoke a separate transaction
        // to generate the next ID
        const id = await (record.id || this.getNextId(params.dataStoreName));

        // once the bove line is done then we can create a new transaction
        const transaction = await this.createTransaction(params.dataStoreName, 'readwrite');
        const store = await transaction.objectStore(params.dataStoreName);

        const request = store.put({
            __status: 'saved',
            __dateStored: new Date(),
            ...record,
            id,
        } as IDBRecord);

        transaction.commit();

        return new Promise((resolve) => {
            request.addEventListener('success', (event) => {
                log.debug(`created:${params.dataStoreName}`, record);
                resolve(request.result);
            });
            request.addEventListener('error', (event) => {
                log.error('error on create', request.error);
                reject(request.error);
            });
        });
    }

    async patch(id: Id, data: IDBRecord, params: IDBParams): Promise<IDBRecord>{
        const record = await this.get(id, params);
        return this.update( id, {
            ...record,
            ...data,
        }, params);
    }

    async update(id: Id, data: IDBRecord, params: IDBParams): Promise<IDBRecord>{
        return this.create(data, params);
    }

    async remove(id: Id, params: IDBParams): Promise<IDBRecord> {
        const record = await this.get(id, params);
        const transaction = await this.createTransaction(params.dataStoreName, 'readwrite');
        const store = await transaction.objectStore(params.dataStoreName);
        const req = store.delete(id);

        return new Promise((resolve, reject) => {
            req.addEventListener('success', () => {
                log.debug(`removed:${params.dataStoreName}`, record);
                resolve(record);
            });
            req.addEventListener('error', () => {
                log.error('error on remove', req.error);
                reject(req.error);
            });
        });
    }

    private upgrade(db: IDBDatabase){

        if(this.__version === 1){
            log.info('upgrading database to version 1');

            // create a settings table to hold next ID's and other props
            const settings = db.createObjectStore('settings', {keyPath: 'id'});
            settings.createIndex('id', 'id', {unique: true});
            settings.createIndex('nextId', 'nextId', {unique: false});

            // create schema tables to hold actual dfp data
            const formSubmissions = db.createObjectStore('formSubmissions', {keyPath: 'id'});
            formSubmissions.createIndex('id', 'id', {unique: true});
            const projects = db.createObjectStore('projects', {keyPath: 'id'});
            projects.createIndex('id', 'id', {unique: true});
            const programs = db.createObjectStore('programs', {keyPath: 'id'});
            programs.createIndex('id', 'id', {unique: true});
            const programSurveys = db.createObjectStore('programsSurveys', {keyPath: 'id'});
            programSurveys.createIndex('id', 'id', {unique: true});
            const projectFiles = db.createObjectStore('projectsFiles', {keyPath: 'id'});
            projectFiles.createIndex('id', 'id', {unique: true});

            // apply default indexes
            defaultIndexes.forEach((i) => {
                formSubmissions.createIndex(i, i, {unique: false});
                projects.createIndex(i, i, {unique: false});
                programs.createIndex(i, i, {unique: false});
                programSurveys.createIndex(i, i, {unique: false});
                projectFiles.createIndex(i, i, {unique: false});
            });

        }
    }

    private createTransaction(storeName: DataStoreOptions, mode: IDBTransactionMode = 'readonly'): IDBTransaction{
        const transaction = this.db.transaction(storeName, mode);
        return transaction;
    }

    private getDataStore(storeName: DataStoreOptions, mode?: IDBTransactionMode){
        const transaction = this.createTransaction(storeName);
        const store = transaction.objectStore(storeName);
        return store;
    }

    private async getNextId(storeName: DataStoreOptions){
        const settings = await this.get(storeName, {dataStoreName: 'settings'})
            .catch((e) => {
                return undefined;
            }) as SettingsRecord|undefined;
        const nextId = settings?.nextId || -1;
        await this.patch(storeName, {
            id: storeName,
            nextId: nextId - 1,
        } as SettingsRecord, {dataStoreName: 'settings'});

        return nextId;
    }

    private async createCursor(storeName: DataStoreOptions): Promise<IDBCursorWithValue>{
        const store = this.getDataStore(storeName);

        return new Promise((resolve, reject) => {
            const cursor = store.openCursor();
            cursor.addEventListener('success', (event) => {
                resolve(cursor.result);
            });
            cursor.addEventListener('error', (event) => {
                reject(cursor.error);
            });
        });
    }

    private filter(data: any[], q: Record<string, any> = {}): any[] {
        const omit = [
            '$skip', '$sort', '$limit', '$select', '$eager', '$modify',
        ];
        const newQuery = {};
        Object.keys(q)
            .filter((key) => {
                return (
                    !omit.includes(key) &&
                typeof q[key] !== 'undefined'
                );
            })
            .forEach((key) => {
                newQuery[key] = q[key];
            });

        const result = data.filter(sift(newQuery));

        return result;
    }
}
