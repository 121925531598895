import dayjs from 'dayjs';

export class DateValueConverter {
    toView(value, format = 'YYYY-MM-DD h:mm A') {
        if (!value) {
            return value;
        }
        return dayjs(value).format(format);
    }
    fromView(value) {
        if(!value){
            return value;
        }
        return dayjs(value).toDate();
    }
}
