import { getFileIcon } from '@wsb_dev/datafi-shared/lib/types/FileTypes';

export class FileIconValueConverter {
    toView(value) {
        return getFileIcon(value);
    }

    fromView(value) {
    //
    }
}
