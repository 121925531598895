import { MESSAGE_CLASSES } from '@wsb_dev/datafi-shared/lib/types/ActionMessageData';
import { DefaultActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';
import { ActionMessageData } from '@wsb_dev/datafi-shared/lib/types/ActionMessageData';

import { autoinject } from 'aurelia-framework';
import { MdcSnackbarService } from '@aurelia-mdc-web/snackbar';

@autoinject
export class AlertService {
    constructor(
        private alerts: MdcSnackbarService,
    ) { }

    processingAlert = false;
    alertQueue: ActionMessageData[] = [];

    display():void {
        const nextAlert = this.getNext();

        if(!nextAlert){
            this.processingAlert = false;
            return;
        }

        this.processingAlert = true;

        const actions: string[] = nextAlert.actions?.map((a: DefaultActionData) => a.label);
        nextAlert.classes = nextAlert.classes || [];
        const classes = [
            ...nextAlert.classes,
            nextAlert.level ? MESSAGE_CLASSES[nextAlert.level] : '',
        ];

        this.alerts.open(nextAlert.label, actions, {
            classes: classes.join(' '),
            timeout: nextAlert.timeout,
            dismissible: nextAlert.dismissable,
        }).then((result) => {
            if (result) {
                this.display();
                // const action = nextAlert.actions?.find((a) => a.label === result);
                // if (action) {
                //     return this.actions.create(action);
                // }
            }
        });
    }

    getNext(): ActionMessageData | undefined {
        return this.alertQueue.length ? this.alertQueue.shift() : undefined;
    }

    async create(data: ActionMessageData): Promise<void> {

        this.alertQueue.push(data);
        if(!this.processingAlert) {
            this.display();
        }
    }
}
