import { isPrimitive } from '../../util/objects/isPrimitive';
import { EventAggregator } from 'aurelia-event-aggregator';
import { LogManager, autoinject } from 'aurelia-framework';
import { Container } from 'aurelia-dependency-injection';
import get from 'lodash.get';

import { ensureArray } from '../../util/array/ensureArray';
import { serialize } from '../../util/objects/serialize';
import { Task } from './tasks/Task';
import { Events } from '@wsb_dev/datafi-shared/lib/types/Events';
import { ActionModule, DefaultActionData, ActionStatus, ActionTargetType } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import GenerateCSVTask from './tasks/GenerateCSV';
import GenerateKoboURLTask from './tasks/GenerateKoboURL';
import GeneratePDFTask from './tasks/GeneratePDF';
import PopulateTask from './tasks/Populate';
import QueryTask from './tasks/Query';
import SaveFile from './tasks/SaveFile';
import EditDFPForm from './tasks/EditDFPForm';
import ExecuteJobTask from './tasks/ExecuteJob';

const log = LogManager.getLogger('dfp:actions');

@autoinject
export class ActionService {
    constructor(
        private ea: EventAggregator,
        private di: Container,
        private api: DatafiProAPI,
    ) {
        this.ea.subscribe(Events.APP_ACTION_START, this.handleAction.bind(this));
        this.di.registerSingleton('GenerateCSV', GenerateCSVTask);
        this.di.registerSingleton('GenerateKoboURL', GenerateKoboURLTask);
        this.di.registerSingleton('GeneratePDF', GeneratePDFTask);
        this.di.registerSingleton('Populate', PopulateTask);
        this.di.registerSingleton('Query', QueryTask);
        this.di.registerSingleton('SaveFile', SaveFile);
        this.di.registerSingleton('EditDFPForm', EditDFPForm);
        this.di.registerSingleton('ExecuteJob', ExecuteJobTask);
    }

    handleAction(actionData: DefaultActionData): Promise<DefaultActionData> {
        log.warn('DEPRECATED: call create instead of handleAction');
        return this.create(actionData);
    }

    /**
     * The action process orchestrator to import actions, run them, and publish events
     * @param actionData {DefaultActionData} the action data to run
     */
    create(actionData: DefaultActionData): Promise<DefaultActionData> {
        actionData = this.getStandardActionData(actionData);
        if (actionData.updateTargetStatus) {
            actionData.target.forEach((t) => t.__status = ActionStatus.PENDING);
        }
        const promise = this.getTask(actionData)
            .then((task) => this.executeTask(task, actionData))
            .then((executeResult) => ({
                ...executeResult,
                status: ActionStatus.SUCCESS,
            })).catch((e) => {
                log.error(e);
                return {
                    ...actionData,
                    error: e,
                    status: ActionStatus.FAILED,
                };
            });

        promise.then((result) => {
            const resultEvent = {
                [ActionStatus.SUCCESS]: Events.APP_ACTION_SUCCESS,
                [ActionStatus.FAILED]: Events.APP_ACTION_FAIL,
            }[result.status];

            if (actionData.updateTargetStatus) {
                actionData.target.forEach((t) => t.__status = result.status);
            }

            const logMethod = {
                [ActionStatus.SUCCESS]: 'info',
                [ActionStatus.FAILED]: 'error',
            }[result.status];
            log[logMethod](`Action<${actionData.id}>: ${resultEvent}`, result);

            this.ea.publish(resultEvent, result);
            this.ea.publish(Events.APP_ACTION_END, result);
        });

        this.ea.publish(Events.APP_ACTION_PENDING, {
            ...actionData,
            promise,
            status: ActionStatus.PENDING,
        });

        return promise;
    }

    private getStandardActionData(data: DefaultActionData): DefaultActionData {
        const auth = this.api.auth.authentication;
        const dynamic = this.getDynamicData(data);
        return {
            ...data,
            target: ensureArray(serialize(data.target)),
            ...dynamic,
            auth: auth,
        };
    }

    private getDynamicData(data: DefaultActionData) {
        const dynamic = data.dynamicProps || {};
        const newData = {};
        Object.keys(dynamic).forEach((key) => {
            const value = get(data, dynamic[key]);
            if (typeof value === 'undefined') {
                return;
            }

            if (isPrimitive(value)) {
                newData[key] = value;
            } else if (Array.isArray(value)) {
                // if the array is empty, skip it
                if (!value.length) {
                    return;
                }

                newData[key] = value;
            } else {
                /// if its an object, mixin with existing data
                newData[key] = {
                    ...data[key],
                    ...value,
                };
            }
        });
        return newData;
    }

    private async getTask(actionData: DefaultActionData): Promise<Task> {
        return this.di.get(actionData.id);
    }

    private executeTask(task: ActionModule<DefaultActionData>, actionData: DefaultActionData): Promise<any> {
        log.info(`Executing action<${actionData.id}> with data`, actionData);
        if (actionData.defaultQuery) { delete actionData.defaultQuery?.__expanded; }
        return Promise.resolve(task.execute(actionData))
            .then((result) => this.getTaskResult(result, actionData));
    }

    private getTaskResult(result: unknown, actionData: DefaultActionData): Promise<DefaultActionData> {

        // result is always an array
        const newResult = Array.isArray(result) ? result : [result];
        if (!actionData.next) {
            return Promise.resolve({ ...actionData, result: newResult });
        }

        const nextAction = actionData.next;
        const firstTarget = actionData.firstTarget || actionData.target;
        let nextTarget;

        // get target from either result or target
        switch (nextAction.targetType) {
        case ActionTargetType.TARGET_FROM_FIRST_TARGET:
            nextTarget = firstTarget;
            break;
        case ActionTargetType.TARGET_FROM_LAST_TARGET:
            nextTarget = actionData.target;
            break;
        case ActionTargetType.TARGET_FROM_LAST_RESULT:
        default:
            nextTarget = newResult;
            break;
        }

        const nextActionData = {
            ...nextAction,
            firstTarget,
            target: nextTarget,
            viewModel: actionData.viewModel,
        };

        // return next action
        return this.create(nextActionData);
    }
}
