import { LogManager } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
const log = LogManager.getLogger('dfp:json');

ValidationRules.customRule(
    'json',
    (value) => {
        log.debug('json value: ', value);
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            log.warn(e);
            return false;
        }
    },
    '${$displayName} must contain valid json',
);
