export class ArrayValueConverter {
    toView(value: any[]) {
        if(['string', 'number', 'boolean'].includes(typeof value[0])){
            return value.join(', ');
        }

        return 'multiple values';
    }

    fromView(value) {
    //
    }
}
