import { Service } from '@feathersjs/feathers';
import { Job } from '@wsb_dev/datafi-shared/lib/types/Job';
import { LogManager } from 'aurelia-framework';
const log = LogManager.getLogger('dfp:api');

const MAX_POLL_COUNT = 50;

export function getJobResult<T>(service: Service<Job<T>>, job: Job<T>, maxPollCount = MAX_POLL_COUNT): Promise<Job<T>> {

    return new Promise((resolve, reject) => {
        let pollCount = 0;
        const interval = setInterval(() => {
            log.debug('Polling for job', job.id);
            pollCount++;
            if (pollCount > MAX_POLL_COUNT) {
                clearInterval(interval);
                return;
            }

            service.get(job.id, {
                query: { $select: ['id', 'result', 'error', 'status'] },
            }).then((jobResult) => {

                const status = jobResult.status;

                if (status === 'waiting' || status === 'active' || status === 'delayed' || status === 'waiting-children') {
                    return;
                }

                log.debug('Job ', job.id, jobResult);
                if (status === 'completed') {
                    clearInterval(interval);
                    resolve(jobResult);
                    return;
                }

                if (status === 'failed') {
                    clearInterval(interval);
                    reject(new Error(jobResult.error));
                }
            });
        }, 2000);
    });
}
