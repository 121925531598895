import { ProgramAsset } from '@wsb_dev/datafi-shared/lib/types/ProgramAsset';
import { computedFrom } from 'aurelia-framework';
import { getterThrottle } from '../../util/decorators/getterThrottle';

export class ActiveAssetsService {
    public assets: ProgramAsset[] = [];
    public activeAsset: ProgramAsset;
    public counter: number;

    @getterThrottle(100)
    @computedFrom('assets')
    public get assetGroups(): Record<string, ProgramAsset[]> {
        return this.assets.reduce((r, a) => {
            r[a.metadata.id_title] = [...r[a.metadata.id_title] || [], a];
            return r;
        }, {});
    }

    constructor() {
        this.counter = -1;
    }

    async create(createAsset: ProgramAsset): Promise<ProgramAsset> {
        if(!createAsset.id){
            createAsset.id = this.generateId();
        }

        this.assets = [
            ...this.assets,
            createAsset,
        ];

        this.activeAsset = createAsset;

        return createAsset;
    }

    /**
     * Searches for an asset by ID and returns the result,
     * or throws error not found if it isn't found.
     */
    async get(id: number): Promise<ProgramAsset>{
        const item = this.assets.find((items) => {
            if (items.id === id) {
                return items;
            }
        });
        this.activeAsset = item;
        return item;
    }

    generateId(): number {
        return  this.counter --;
    }

    getAssets(): ProgramAsset[] {
        return this.assets;
    }

    async remove(id: number): Promise<void> {
        this.assets = this.assets.filter((items) => {
            if (items.id !== id) {
                return items;
            }
        });
    }
}
