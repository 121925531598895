import { autoinject } from 'aurelia-framework';
import { EventEmitter } from 'events';
import { ValidatedProject } from '../../types/Project';
import { subscribe } from '../../util/decorators/subscribe';
import { MapAdaptor, SelectResult } from './MapAdaptor';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { Project } from '@wsb_dev/datafi-shared/lib/types/Project';
import { Paginated } from '@feathersjs/feathers';
import { Router } from 'aurelia-router';
import { ActiveProgram } from './ActiveProgram';

@autoinject
@subscribe({
    events: [
        { eventEmitter: 'ma', event: 'select', fn: 'onFeatureSelect' },
        { eventEmitter: 'ma', event: 'deselect', fn: 'onFeatureDeselect' },
    ],
    attached: 'attached',
    detached: 'detached',
})

export class ActiveProjects extends EventEmitter {
    selectedProjects: ValidatedProject[] = [];

    constructor(
        private ma: MapAdaptor,
        public api: DatafiProAPI,
        public router: Router,
        public program: ActiveProgram,
    ) {
        super();
        this.attached();
    }

    onFeatureSelect = async (evt: SelectResult) => {
        let selectedIds = [];
        const items = evt.target.filter((item) => item.layerId === 'projectFeatures');
        if (!items.length || !items) {
            return;
        }
        selectedIds = items.map((i) => i.properties.id);
        this.select(selectedIds);
    }

    onFeatureDeselect = (evt: SelectResult) => {
        const items = evt.target.filter((item) => item.layerId === 'projectFeatures');
        if (!items.length || !items) {
            return;
        }

        const ids = items.map(({ properties: project }) => project.id);

        this.selectedProjects = this.selectedProjects.filter((project) => {
            return !ids.includes(project.id);
        });

        this.emit('projectSelectionChanged', this.selectedProjects );
    }

    select(ids: number[]) {

        if (ids.length < 1) {
            this.ma.select([{ ids }], 'projectFeatures', 'id');
            this.selectedProjects = this.selectedProjects.filter((project) => ids.includes(project.id));
            this.emit('projectSelectionChanged', this.selectedProjects);
        } else {
            this.ma.select(ids.map((id) => ({ id })), 'projectFeatures', 'id');
            this.api.projects.find({
                query: {
                    id: { $in: ids },
                    program_id: this.program.id,
                },
            }).then((res: Paginated<Project>) => {
                this.selectedProjects = res.data;
                this.emit('projectSelectionChanged', this.selectedProjects); // TODO: this is not emitting when on Project Details page, causing project list to not update
            });
        }
    }

    attached() { return this; }
    detached() { return this; }

}
