import { WatchParams } from '@wsb_dev/datafi-shared/lib/types/Location';
import EventEmitter from 'events';

export interface ILocationProvider extends EventEmitter {
    activate(params: WatchParams): void;
    deactivate(): void;
    isActive(): boolean;
}

export enum LOCATION_ERROR {
    UNKNOWN_ERROR = 0,
    PERMISSION_DENIED = 1,
    POSITION_UNAVAILABLE = 2,
    TIMEOUT = 3,
    NOT_SUPPORTED = 4,

    // for trimble or web socket connections
    TRIMBLE_WS_CONNECTION_ERROR = 5000,
}

export const LOCATION_MESSAGES: Record<LOCATION_ERROR, string> = {
    [LOCATION_ERROR.UNKNOWN_ERROR]: 'An unknown error occurred while trying to access location',
    [LOCATION_ERROR.PERMISSION_DENIED]: 'Permission denied while trying to access location',
    [LOCATION_ERROR.POSITION_UNAVAILABLE]: 'Geolocation is not available at this time',
    [LOCATION_ERROR.TIMEOUT]: 'Timeout exceeded while retrieving location',
    [LOCATION_ERROR.NOT_SUPPORTED]: 'Geolocation is not supported on this platform',
    [LOCATION_ERROR.TRIMBLE_WS_CONNECTION_ERROR]: 'Connection to Trimble device failed, please check Trimble application and device status',
};
