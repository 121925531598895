import get from 'lodash.get';
import { HookContext } from '@feathersjs/feathers';
import { LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('dfp:api');

export function log(...props) {
    if(!props){
        props = [];
    }
    return function (context: HookContext) {
        const name = context.service.name || context.service.path;
        const level = context.type === 'error' ?
            'error' :
            'debug';
        logger[level](`${name}<${context.method}>: `, ...props.map((p) => get(context, p)));
    };
}
