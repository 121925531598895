import { LocationError, LocationResponse, WatchParams } from '@wsb_dev/datafi-shared/lib/types/Location';
import { LogManager } from 'aurelia-framework';
import { EventEmitter } from 'events';
import { ILocationProvider, LOCATION_ERROR, LOCATION_MESSAGES } from './ILocation';

const logger = LogManager.getLogger('dfp:Geolocation');

export class BrowserGeolocationProvider extends EventEmitter implements ILocationProvider {
    private watchId;

    activate(params: WatchParams): void  {
        // check for browser location capability
        if (!navigator.geolocation) {
            const e = new Error(LOCATION_MESSAGES[LOCATION_ERROR.NOT_SUPPORTED]);
            this.emit('error', {
                error: e,
                message: e.message,
                code: LOCATION_ERROR.NOT_SUPPORTED,
            } as LocationError);
            return;
        }

        // handle watchers
        if (params?.$single) {
            navigator.geolocation.getCurrentPosition(
                this.dispatchLocation.bind(this),
                this.dispatchError.bind(this),
            );
        } else {
            logger.info('Creating new position watch');
            this.watchId = navigator.geolocation.watchPosition(
                this.dispatchLocation.bind(this),
                this.dispatchError.bind(this),
                params,
            );
            logger.info('Created new watchId: ' + this.watchId);
        }
    }

    dispatchError(error: GeolocationPositionError): void{
        logger.error('Error:', error);
        const e = new Error(error.message);
        this.emit('error', {
            error: e,
            message: e.message,
            code: LOCATION_ERROR.TIMEOUT,
        } as LocationError);
    }

    dispatchLocation(location: GeolocationPosition): void{

        // Object.keys does not work on this object :(
        const props = [
            'accuracy',
            'altitude',
            'altitudeAccuracy',
            'heading',
            'latitude',
            'longitude',
            'speed',
        ];

        const data: LocationResponse = {timestamp: new Date().getTime()};
        props.forEach((prop) => data[prop] = location.coords[prop]);

        this.emit('location', data);
    }

    deactivate(): void{
        logger.info('Deactivating GPS');
        navigator.geolocation.clearWatch(this.watchId);
        this.watchId = null;
    }

    isActive(): boolean {
        return !!this.watchId;
    }

}
