import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { ReportJobData } from '@wsb_dev/datafi-shared/lib/types/jobs/ReportJobData';
import { LogManager, autoinject } from 'aurelia-framework';
import { Job } from '@wsb_dev/datafi-shared/lib/types/Job';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { Field, GeneratePDFActionData } from '@wsb_dev/datafi-shared/lib/types';
import { getJobResult } from '../../../services/api/util/jobs/getJobresult';
import get from 'lodash.get';
import set from 'lodash.set';

const log = LogManager.getLogger('dfp:actions');

@autoinject
export default class GeneratePDFTask {
    constructor(
        private api: DatafiProAPI,
    ) {}
    async execute(data: GeneratePDFActionData): Promise<APIFile|Error> {
        if(data.attachments){
            await Promise.all(data.target.map((item) => this.populateAttachments(item)));
        }

        if(data.viewModel.fields){
            data.target = this.fixObjectArrays(data.target, data.viewModel.fields);
        }

        const jobData = {
            name: data.template || data.name,
            format: data.format,
            version: data.version,
            data: {
                objects: data.target,
                timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        };

        return this.api.jobs.create({
            name: 'report',
            data: jobData,
        } as Job<ReportJobData>)
            .then((job) => getJobResult(this.api.jobs, job))
            .then((job) => job.result);
    }

    populateAttachments(record: Record<string, any>): Promise<void>{
        const attachmentsService = record.attachments?.length ?
            'api/v1/files' :
            record._attachments?.length ?
                'api/v2/surveys-media' :
                null;

        if(!attachmentsService){
            return;
        }

        const service = this.api.app.service(attachmentsService);
        const attachments = record.attachments || record._attachments || [];

        return Promise.all(attachments.map((att) => {
            const fileId = attachmentsService === 'api/v1/files' ?
                att.id :
                att.filename;

            return service.get(fileId, {query: {$url: true}})
                .catch((e) => undefined);
        }))
            .then((files) => {
                files = files.filter((f) => !!f);
                attachments.forEach((att) => {
                    const fileId = attachmentsService === 'api/v1/files' ?
                        att.id :
                        att.filename;

                    const foundFile = files.find((file) => file.id === fileId);
                    att.url = foundFile?.url;
                });
            });
    }

    fixObjectArrays(rows: Record<string, any>[], fields: Field[]): Record<string, any>[] {
        const arrays = fields.filter((field) => field.type === 'array');

        rows.forEach((row) => {
            arrays.forEach((arrayField) => {
                const arrayValue = get(row, arrayField.path);
                if(Array.isArray(arrayValue)){
                    return;
                }

                if(!arrayValue){
                    return;
                }

                log.debug(`fixing invalid array field ${arrayField.path}`, arrayValue);
                set(row, arrayField.path, [arrayValue]);
            });
        });

        return rows;
    }
}
