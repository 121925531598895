import { EventAggregator } from 'aurelia-event-aggregator';
import { inject, LogManager } from 'aurelia-framework';
import { debounced } from '../../util/decorators/debounced';
import {
    LocationResponse, LOCATION_ERROR, LOCATION_MESSAGES,
    WatchParams, LocationError, LocationFail, LocationSuccess,
} from '@wsb_dev/datafi-shared/lib/types/Location';
import { ILocationProvider } from './Geolocation/ILocation';
import { BrowserGeolocationProvider } from './Geolocation/Browser';
import { subscribe } from '../../util/decorators/subscribe';
import { EventEmitter } from 'events';

const logger = LogManager.getLogger('dfp:Geolocation');

export type ProviderType = 'default' | 'trimble';

const providers = {
    default: BrowserGeolocationProvider,
    // trimble: TrimbleLocationProvider, // TODO -> replace with datafi pro api location provider
};

@subscribe({
    events: [
        {event: 'location', fn: 'handleLocation', eventEmitter: 'provider'},
        {event: 'error', fn: 'handleError', eventEmitter: 'provider'},
    ],
    attached: '_enable',
    detached: 'disable',
})
@inject(EventAggregator)
export class GeolocationService extends EventEmitter {
    public location: LocationResponse;
    private provider: ILocationProvider;

    constructor(private ea: EventAggregator) {
        super();
        this.provider = new providers.default;
    }

    enable(params?: WatchParams): void {

        logger.info('Activating GPS');

        if (this.provider.isActive()) {
            logger.info('GPS is already active');
            return;
        }
        this._enable();
    }

    private _enable(params?: WatchParams){
        params = {...params};
        this.provider.activate(params);
    }

    disable(): void {
        if (!this.provider.isActive()) {
            return;
        }
        this.provider.deactivate();
    }

    setProvider(providerName: ProviderType): void{
        this.disable();
        this.provider = new providers[providerName];
    }

    @debounced(200)
    protected handleLocation(location): void {
        logger.info('GPS Response', location);
        this.location = {...location};
        this.ea.publish(new LocationSuccess(this.location));
        this.emit('location', this.location);
    }

    @debounced(200)
    protected handleError(error: LocationError): void {
        const message = error.message || LOCATION_MESSAGES[error.code] || LOCATION_MESSAGES[LOCATION_ERROR.UNKNOWN_ERROR];

        logger.info('GPS Error', error, message);

        this.location = { error, timestamp: new Date().getTime() };
        this.ea.publish(new LocationFail(this.location.error));
        this.emit('error', this.location.error);
    }
}
