import { recursiveQuery } from '../../../util/query/recursive';
import { Query, Service } from '@feathersjs/feathers';
import { LogManager, autoinject } from 'aurelia-framework';
import { RequestData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import {Task} from './Task';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
const log = LogManager.getLogger('dfp:actions');

@autoinject
export default class QueryTask implements Task<RequestData> {
    constructor(private api: DatafiProAPI) { }
    async execute(data: RequestData) {
        const { parentField, childField, target, service, defaultQuery } = data;
        const query: Query = {
            ...defaultQuery,
            $limit: 1000,
        };

        const appService = this.api.app.service(service) as Service<any>;
        if (parentField && childField) {
            const queryValues = Array.from(
                new Set(
                    target
                        .map((row) => {
                            const value = row[parentField];
                            if (typeof value === 'undefined') {
                                log.warn(`Row is missing parentField<${parentField}>`, row);
                            }
                            return value;
                        })
                        .filter((value) => !!value),
                ),
            );

            query[childField] = queryValues.length === 1 ?
                queryValues[0] :
                { $in: queryValues };
        }

        return recursiveQuery(appService, query, []);
    }
}
