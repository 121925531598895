import { EditDFPFormData } from '@wsb_dev/datafi-shared/lib/types';
import { Task } from './Task';
import { DatafiProAPI } from '../../api/DatafiProAPI';
import { autoinject } from 'aurelia-dependency-injection';
import get from 'lodash.get';
import { LogManager } from 'aurelia-framework';
import { FormSubmission } from '@wsb_dev/datafi-shared/lib/types/FormSubmission';

const log = LogManager.getLogger('dfp:actions');
interface URLResult {
    url: string;
    name?: string;
    detail?: string;
}

@autoinject
export default class EditDFPForm implements Task {
    constructor(
        private api: DatafiProAPI,
    ) { }
    async execute(data: EditDFPFormData) {
        data.program = await (data.program || (data.programId ? this.api.programs.get(data.programId) : null));
        data.survey = await (data.survey || (data.surveyId ? this.api.programSurveys.get(data.surveyId) : null));
        data.project = await (data.project || (data.projectId ? this.api.projects.get(data.projectId) : null));
        data.formSubmission = await (data.formSubmission || (data.formSubmissionId ? this.api.formSubmissions.get(data.formSubmissionId) : null));

        // determine if target is a formSubmission
        if (data.target[0] as FormSubmission) {
            // not ideal, but if the target has an attachments property, its probably a form submission
            // when we develop editing capabilities, maybe we can get and validate an instanceId instead
            data.formSubmission = data.target[0].attachments ? data.target[0] : null;
        }
        const urlResult = await ( data.formSubmission ? this.getEditUrl(data) : this.getNewSurveyUrl(data));
        window.open(urlResult.url);
    }

    async getEditUrl(data: EditDFPFormData): Promise<URLResult> {

        const baseUrl = `/dfp-form/program/${data.formSubmission.program_id || data.formSubmission.programId}/survey/${data.surveyId}`;
        const metadataParam = `/submissionId=${data.formSubmission.id}`;
        const surveyURL = baseUrl + metadataParam;

        // url to edit existing survey
        return {
            url: surveyURL,
            name: `Edit Form: ${data.target?.[0]?.project_name || 'Blank'}`,
        };
    }

    async getNewSurveyUrl(data: EditDFPFormData): Promise<URLResult> {
        // define base url
        const baseUrl = `/dfp-form/program/${data.program.id || data.programId}/survey/${data.surveyId}`;
        //get defaults as metadata url string
        const metadataUrl = this.getDefaults(data);
        //return url object
        return {
            url: baseUrl + metadataUrl,
            name: `New Form: ${data.target?.[0]?.project_name || 'Blank'}`,
        };
    }

    getDefaults(data: EditDFPFormData) {
        const defaults = {};
        if (data.actionFields) {
            Object.keys(data.actionFields).forEach((field) => {
                const result = get(data, data.actionFields[field]);
                if (result) {
                    defaults[field] = result;
                }
            });
        }

        log.debug('populating defaults', defaults);

        const defaultsParam = new URLSearchParams(defaults).toString().replace(/\./g, '%2e');
        return `/${encodeURI(defaultsParam)}`;
    }
}
