import { SurveyItemField } from '@wsb_dev/datafi-shared/lib/types/SurveyItem';

export interface IXcelJsonConfig {
    prop: keyof SurveyItemField;
    type: any;
}

export const xFormSchema: Record<keyof SurveyItemField, IXcelJsonConfig> = {
    type: {prop: 'type', type: String},
    label: {prop: 'label', type: String},
    name: {prop: 'name', type: String},
    hint: {prop: 'hint', type: String},
    guidance_hint: {prop: 'guidance_hint', type: String},
    appearance: {prop: 'appearance', type: String},
    required: {prop: 'required', type: String},
    required_message: {prop: 'required_message', type: String},
    default: {prop: 'default', type: String},
    relevant: {prop: 'relevant', type: String},
    calculation: {prop: 'calculation', type: String},
    constraint: {prop: 'constraint', type: String},
    constraint_message: {prop: 'constraint_message', type: String},
    readonly: {prop: 'readonly', type: String},
    repeat_count: {prop: 'repeat_count', type: Number},
    image: {prop: 'image', type: String},
    audio: {prop: 'audio', type: String},
    video: {prop: 'video', type: String},
    parameters: {prop: 'parameters', type: String},

};

export const xformChoicesSchema = {
    list_name: {prop: 'list_name', type: String},
    name: {prop: 'name', type: String},
    label: {prop: 'label', type: String},
};
