import { HookContext } from '@feathersjs/feathers';
import { hasValue } from '@wsb_dev/datafi-shared/lib/util/types/hasValue';
import omit from 'lodash.omit';

export function omitEmpty(...contextProps: string[]) {
    return function omitEmptyHook(context: HookContext): HookContext {
        const propsToOmit = Object.keys(context.data).filter((k) => {
            return contextProps.indexOf(k) && !hasValue(context.data[k]);
        });

        context.data = omit(context.data, propsToOmit);
        return context;
    };
}
