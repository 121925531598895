import { HookContext } from '@feathersjs/feathers';
import omit from 'lodash.omit';

export function omitGeomIfBinary(geomField: string){
    return function omitGeom(context: HookContext): HookContext {
        if(typeof context.data?.[geomField] === 'string'){
            context.data = omit(context.data, geomField);
        }

        return context;
    };
}
