import { Params } from '@feathersjs/feathers';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AuthenticationResult } from '@feathersjs/authentication';
import { LogManager, autoinject } from 'aurelia-framework';
import { AuthCreated, AuthDestroyed } from '@wsb_dev/datafi-shared/lib/types/Events';
import {getTopLevelDomain} from '@wsb_dev/datafi-shared/lib/util/strings/getTopLevelDomain';
import { ValidatedCredentials } from '../../types/Credentials';
import { ValidatedUser } from '../../types/Users';
import { AppConfig } from '../../services/util/AppConfig';
import { BrowserStorage } from '../../services/util/BrowserStorage';
import { DFPAuthResult } from '../../types/Authentication';
import { FeathersApplication } from '../../types/Application';
import { FeatureFlagsService } from '../../services/util/FeatureFlags';
const log = LogManager.getLogger('dfp:api');

@autoinject
export class AuthenticationService {
    public cookie = 'feathers-jwt';
    public authentication?: DFPAuthResult;
    public me?: ValidatedUser;
    private authPromise?: Promise<AuthenticationResult | void>;
    public defaultParams = { query: { $eager: '[roles.role, programRoles.role]' }}

    constructor(
        private app: FeathersApplication,
        private ea: EventAggregator,
        private config: AppConfig,
        private browserStorage: BrowserStorage,
        private flags: FeatureFlagsService,
    ) {
        this.authPromise = this.reAuthenticate()
            .then((auth) => this.updateAuth(auth))
            .catch((e) => {
                log.warn('auth error', e);
            });
    }

    get() {
        if (this.authentication) {
            return Promise.resolve(this.authentication);
        }

        return this.authPromise;
    }

    login(creds: ValidatedCredentials, params?: Params) {
        params = { ...params, ...this.defaultParams };
        return this.app.authenticate(creds, params)
            .then((result) => this.updateAuth(result));
    }

    logout() {
        return this.app.logout()
            .then(() => this.updateAuth());
    }

    authenticate(data: any, params?: Params) {
        return this.app
            .authenticate(data, params)
            .then((auth) => this.updateAuth(auth));
    }

    reAuthenticate(force = false, strategy?: string): Promise<AuthenticationResult | void> {
        if (!force && this.authentication) {
            return Promise.resolve(this.authentication);
        }

        return this.app.authentication
            .getAccessToken()
            .then((accessToken) => {
                if (!accessToken) {
                    throw new Error('No accessToken found in storage');
                }

                return this.app.authentication.authenticate({
                    strategy: strategy || this.app.authentication.options.jwtStrategy,
                    accessToken,
                }, {
                    ...this.defaultParams,
                });
            })
            .catch((e) => {
                if(e.code === 408){  // timeout error
                    return this.browserStorage.get('dfp-auth')
                        .then((res) => res.data);
                }
            });
    }

    updateAuth(auth?: AuthenticationResult | void): DFPAuthResult {
        log.info('[authentication]: updating auth', auth);

        if (!auth) {
            this.authentication = null;
            this.me = null;
            this.updateCookie();
            this.ea.publish(new AuthDestroyed());
            return this.authentication;
        }

        // store the raw auth result
        this.browserStorage.create({
            id: 'dfp-auth',
            data: auth,
        });

        const authResult: DFPAuthResult = {
            ...auth,
            user: auth ? new ValidatedUser(auth.user, this.app.service('api/v1/files')) : null,
        } as DFPAuthResult;

        this.authentication = authResult;
        this.me = authResult?.user as ValidatedUser;
        this.updateCookie(this.authentication);
        this.ea.publish(new AuthCreated(auth as DFPAuthResult));
        this.flags.updateUnleashUser({ auth: authResult });

        return this.authentication;
    }

    updateCookie(auth?: DFPAuthResult): void {
        if (!auth) {
            document.cookie = `${this.cookie}=; Max-Age=0; path=/; domain=`;
            return;
        }
        const expires = (new Date(Date.now() + 86400 * 1000)).toUTCString();
        document.cookie = `${this.cookie}=${auth.accessToken}; path=/; expires=${expires}; SameSite=Strict; domain=${getTopLevelDomain(window.location.hostname)}`;
    }
}
