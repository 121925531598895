import { autoinject } from 'aurelia-dependency-injection';
import { NavigationInstruction, Next, RedirectToRoute, Redirect } from 'aurelia-router';
import { AuthenticationService } from '../../services/api/authentication';
import { BrowserStorage } from './BrowserStorage';
import { StorageItem } from './_StorageBase';

@autoinject
export class AuthorizeStep {
    constructor(private auth: AuthenticationService, private storage: BrowserStorage){}
    async run(navigationInstruction: NavigationInstruction, next: Next) {
        // this function gets called any time the route changes
        // so watch out for infinite loops when returning redirects

        return this.auth.get()
            .catch(() => undefined)
            .then(async (auth) => {
                if(auth){
                // authenticated
                    const redirect: StorageItem|undefined = await this.storage.get('saved_path')
                        .catch((e) => undefined);

                    if(redirect){
                        this.storage.remove('saved_path');
                        // if the redirect is where we are already trying to go, great!
                        if(redirect.data.path === navigationInstruction.config.name){
                            return next();
                        }

                        // otherwise cancel the instruction and go to redirect
                        return next.cancel(new RedirectToRoute(redirect.data.path, redirect.data.params));
                    }

                    return next();

                }

                // not authenticated
                if(navigationInstruction.config.settings?.auth){
                    await this.storage.create({
                        id: 'saved_path',
                        data: {path: navigationInstruction.config.name, params: navigationInstruction.params},
                    });
                    return next.cancel(new Redirect('login'));
                }

                return next();
            });

    }

}
