import { HookContext } from '@feathersjs/feathers';
import omit from 'lodash.omit';

const RE = /^_/;

export function omitHiddenProps(...contextProps: string[]) {
    return function (context: HookContext) {
        contextProps.forEach((item) => {
            if (!context[item]) {
                return;
            }
            // don't try to omit file object types
            // they get coerced to regular objects
            if (context[item] instanceof File) {
                return;
            }

            if (Array.isArray(context[item])) {
                context[item] = context[item].map((item) => {
                    const omittables = Object.keys(item).filter((key) => RE.test(key));
                    return omit(item, omittables);
                });
            } else {
                const omittables = Object.keys(context[item]).filter((key) => RE.test(key));
                context[item] = omit(context[item], omittables);
            }
        });

        return context;
    };
}
