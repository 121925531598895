export interface Group {
    key: string;
    values: any[];
}

export class GroupValueConverter {
    toView(items: any[], key: string): Group[] {
        if (!items) {
            return [];
        }
        const values = {};
        items.forEach((i) => {
            const prop = i[key];
            if (!values[prop]) {
                values[prop] = [];
            }
            values[prop].push(i);
        });

        return Object.keys(values).map((key) => ({ key, values: values[key] }));
    }
}
