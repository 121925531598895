import { LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('dfp:actions');

export interface Item {
    type?: string;
    [key: string]: any;
}

export interface DefaultGetters {
    [key: string] : (...args :any[]) => any;
}

export function mixinDeep(defaults, options){
    for(const prop in options){
        if(options[prop]?.constructor === Object){
            defaults[prop] = mixinDeep(defaults[prop] || {}, options[prop]);
        } else {
            defaults[prop] = options[prop];
        }
    }
    return defaults;
}
export default class ItemFactory {
    public defaults: DefaultGetters = {};
    public items: any = {};

    constructor(items: any, defaults: any){
        this.defaults = {...defaults};
        this.items = {...items};
    }

    public async create(item: Item): Promise<any> {
        if(typeof item === 'undefined'){
            logger.debug('Item is undefined', item);
            return;
        }

        // handle lists
        if(Array.isArray(item)){
            return Promise.all(item.map((i) => this.create(i)));
        }

        // handle non-plain objects
        if(item.constructor !== Object){
            logger.debug('Item is not a plain Object', item);
            return item;
        }

        // handle objects with nested keys
        if(typeof item === 'object'){
            // handle items that should not be parsed
            if(item._skipFactory){
                return item;
            }

            // mixin defaults
            const getDefaults = typeof this.defaults[item.type] !== 'undefined' ?
                this.defaults[item.type] :
                () => ({ });
            const defaults = await getDefaults(item.type);

            const newItem = mixinDeep(defaults, item);

            for(const prop in newItem){
                try {
                    newItem[prop] = await this.create(newItem[prop]);
                } catch(e){
                    logger.debug(`error on ${prop}`, e);
                }
            }
            item = newItem;
        }

        // if its a "typed" object - construct it
        if(item.type){
            logger.debug(`Creating ${item.type}: `, item);
            const ItemConstructor = this.items[item.type];
            if(!ItemConstructor){
                logger.debug(`Item ${item.type} has no constructor`);
                return item;
            }
            logger.debug(`Creating ${item.type}.`, item);
            delete item.type;
            const obj = new ItemConstructor(item);
            return obj;
        } else {
            logger.debug('Item has no type and will be returned as is', item);
        }

        return item;

    }
}
