import { HookContext } from '@feathersjs/feathers';

function getQueries(obj, parent?: string) {
    return Object.keys(obj)
        .map((key) => {

            const value = obj[key];
            if (typeof value === 'undefined') {
                return [];
            }

            if (key.startsWith('$')) {
                return parent ?
                    [{ path: parent, value: { [key]: value } }] :
                    [{ path: key, value }];
            }

            const path = parent ? `${parent}.${key}` : key;

            if (Array.isArray(value)) {
                const type = typeof value[0];
                if (type === 'string' || type === 'number') {
                    return [{ path, value: value.join(',') }];
                }
            }

            if (typeof value === 'object') {
                return getQueries(value, path);
            }
            return [{ path, value }];
        })
        .reduce((result, currentArray) => {
            return result.concat(currentArray);
        }, []);
}

export function convertJsonQuery(context: HookContext) {
    const operators = ['$gt', '$gte', ''];
    if (!context.params.query) {
        return context;
    }
    Object.keys(context.params.query).forEach((key) => {
        if (key.startsWith('$')) {
            return;
        }

        const value = context.params.query[key];
        if (typeof value !== 'object') {
            return;
        }

        context.params.query[key] = {};

        getQueries(value).forEach((item) => {
            if (Object.keys(item.value).find((key) => key.startsWith('$'))) {
                context.params.query[key][item.path] = {
                    ...item.value,
                    ...context.params.query[key][item.path],
                };
            } else {
                context.params.query[key][item.path] = item.value;
            }
        });
    });

    return context;
}
