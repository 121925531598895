import * as format from 'd3-format/dist/d3-format';
/**
 * Number formatter
 * @url https://www.npmjs.com/package/d3-format
 */
export class NumberValueConverter {
    toView(value, f = ',') {
        if (f === '' || isNaN(parseFloat(value))) {
            return value;
        } else {
            return format.format(f)(value);
        }
    }

    fromView(value){
        if(!value){
            return 0;
        }
        return parseFloat(value);
    }
}
