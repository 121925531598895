import { Task } from './Task';

import * as fileSaver from 'file-saver';
import { SavableFile, SaveFileActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import { autoinject} from 'aurelia-framework';
import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { dataUriToBlob } from '../../../services/api/hooks/files/dataUriToBlob';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import _ from 'lodash';

@autoinject

export default class SaveFile implements Task {
    constructor(private api: DatafiProAPI) { }

    customFileName(data: SaveFileActionData): string {
        const regex = /%([^%]+)%/g;
        let match;
        let replacedString = data.name;

        while ((match = regex.exec(data.name)) !== null) {
            let replacement = _.get(data.firstTarget[0], match[1]);
            const isNumber = /^[0-9]+$/.test(replacement);

            if (!isNumber) {
                const date = new Date(replacement);
                if (!isNaN(date.getTime()) && date !== null) {
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();

                    replacement = `${month}-${day}-${year}`;
                }
            }

            if (replacement !== undefined) {
                // Replace each template with its target name
                replacedString = replacedString.replace(match[0], replacement);
            }
        }
        return replacedString;
    }

    async execute(data: SaveFileActionData): Promise<void> {
        const promise: Promise<SavableFile[]> = data.file ?
            Promise.resolve([data.file]) :
            Promise.all(data.target.map((item: any) => {
                if (item.file) {
                    return item.file;
                }
                return this.api.files
                    .get((item as APIFile).id, { query: { $url: true } })
                    .then((result: APIFile) => {
                        if (result.url?.startsWith('data:')) {
                            return dataUriToBlob(result.url);
                        } else if (result.url?.startsWith('http')) {
                            return fetch(result.url).then((data) => data.blob());
                        } else {
                            throw new Error('File could not be downloaded');
                        }
                    });
            }));

        const newName = this.customFileName(data);

        const files = await promise;
        files.forEach((file: any) => {
            fileSaver.saveAs(
                file,
                file.name || newName,
                data.options);
        });
    }
}
