export class RoundValueConverter {
    toView(value, places = 1) {
        const multiplier = Math.pow(10, places);
        return Math.round(multiplier * value) / multiplier;
    }

    fromView(value) {
        return parseFloat(value);
    }
}
