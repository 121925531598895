import { HookContext } from '@feathersjs/feathers';
import { DB, DataStoreOptions } from '../../../services/util/DB';
import camelCase from 'just-camel-case';
import log from '../../../services/util/Log';

function getStoreName(context: HookContext): DataStoreOptions{
    const serviceParts = context.path.split('/');
    const storeName = camelCase( serviceParts[serviceParts.length - 1] );
    return storeName as DataStoreOptions;
}

export function setCacheData(db: DB){

    return async function(context: HookContext){
        const storeName = getStoreName(context) as DataStoreOptions;
        if (!context.params.query?.$client) {
            try {
                if(Array.isArray(context.result?.data)){
                    for(const item of context.result.data){
                        await db.patch(item.id, item, {dataStoreName: storeName});
                    }
                } else if(context.result) {
                    await db.patch(context.result.id, context.result, {dataStoreName: storeName});
                }
            } catch(e) {
                log.warn(e);
            }
        }
        return context;
    };
}
