import { Application, HookContext } from '@feathersjs/feathers';
import { Service } from '@feathersjs/feathers';
import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { convertResult } from './hooks/convertResult';
import feathers from '@feathersjs/feathers';
import restClient from '@feathersjs/rest-client';
import axios from 'axios';
import { LogManager } from 'aurelia-framework';

const log = LogManager.getLogger('dfp:file');

/**
 * Dispatched when upload progress occurs
 *
 * @example
 *
 * ```
 * fileService.on('uploadprogress', (event) => ...);
 * ```
 * @event
 */
export const UPLOAD_PROGRESS = 'uploadprogress';
/**
  * Dispatched when download progress occurs
  *
  * @example
  *
  * ```
  * fileService.on('downloadprogress', (event) => ...);
  * ```
  *
  * @event
  */
export const DOWNLOAD_PROGRESS = 'downloadprogress';
export interface FileService extends Service<APIFile> {}
export class FileService {}

export function createFileService(base: string, defaultApp: Application){

    const app = feathers();

    const axiosInstance = axios.create({
        onUploadProgress(event) {
            log.debug('upload progress', event);
            fileService.emit(UPLOAD_PROGRESS, event);
        },
        onDownloadProgress(event) {
            log.debug('download progress', event);
            fileService.emit(DOWNLOAD_PROGRESS, event);
        },
    });

    app.configure(restClient(base).axios(axiosInstance));

    /**
 * File service for uploading files
 */
    const fileService: FileService = app.service('api/v1/files');

    const BATCH_SIZE = 4;
    app.set('batchSize', BATCH_SIZE);

    fileService.hooks({
        before: {
            all: [
                async function addTokenHeader(context: HookContext) {
                    const auth = await defaultApp.authentication.getAccessToken();
                    context.params.headers = {
                        ...context.params.headers,
                        Authorization: `Bearer ${auth}`,
                    };
                },
            ],
            create: [
            // ensureDataIsArray,
            // batchCreate,
            // blobToDataUriHook,
                (context: HookContext) => {
                    const items = (
                        Array.isArray(context.data) ?
                            context.data : [context.data]
                    ).map((item) => {
                        if(item instanceof File){
                            return {file: File};
                        }
                        return item;
                    });

                    const data = new FormData();
                    const formProp = 'uri';

                    items.forEach((item) => {
                        data.append(formProp, item.file);
                        if (item.id) {
                            data.append('id', item.id);
                        }
                    });

                    context.data = data;
                    context.params.headers = {
                        ...context.params.headers,
                        'Content-Type': 'multipart/form-data',
                    };
                },
            ],

        },
        after: {
            create: [convertResult],
        },
    });

    return fileService;
}
