import { ValidationRules } from 'aurelia-validation';

ValidationRules.customRule(
    'matchesProperty',
    (value, obj, otherPropertyName) =>
        value === null
      || value === undefined
      || value === ''
      || obj[otherPropertyName] === null
      || obj[otherPropertyName] === undefined
      || obj[otherPropertyName] === ''
      || value === obj[otherPropertyName],
    '${$displayName} must match ${$getDisplayName($config.otherPropertyName)}',
    (otherPropertyName) => ({ otherPropertyName }),
);
