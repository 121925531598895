import { Id, Params, Service } from '@feathersjs/feathers';
import { LogManager, autoinject, camelCase, transient } from 'aurelia-framework';
import { DB, DataStoreOptions } from '../../services/util/DB';
import { setCacheData } from './hooks/idb';
import { FeatureFlagsService } from '../../services/util/FeatureFlags';
import {iff} from 'feathers-hooks-common/dist/index';

export type IData = Record<string, unknown>;

@autoinject
@transient()
export class OfflineService<T = Record<string, unknown>> {
    service: Service<T>;
    dataStoreName: DataStoreOptions;
    log: LogManager.Logger;
    timer: NodeJS.Timeout;
    api: any;

    get online(): boolean {
        if(!this.flags.offline_storage_enabled){
            return true;
        }
        return this.api.connected;
    }

    constructor(
        private db: DB,
        private flags: FeatureFlagsService,
    ){
        this.log = LogManager.getLogger('offline');
        const timer = setInterval(() => this.sync(), 1000 * 60 * 5);
    }

    createService(api: any, serviceName: string){
        this.api = api;
        this.service = this.api.app.service(serviceName);
        this.dataStoreName = this.getStoreName(serviceName);

        this.service.hooks({
            after: {
                find: [
                    iff(
                        () => this.flags.offline_storage_enabled,
                        setCacheData(this.db),
                    ),
                ],
                get: [
                    iff(
                        () => this.flags.offline_storage_enabled,
                        setCacheData(this.db),
                    ),
                ],
            },
        });

        return this;
    }

    public async sync(){
        this.log.debug('checking for pending data...');
        if(this.online){
            const pendingResults = await this.db.find({
                dataStoreName: this.dataStoreName,
                query: {
                    id: {$lt: 0},
                    __status: 'pending',
                },
            });

            const pendingRows = pendingResults.data as any[];
            return Promise.all(pendingRows.map(async (pending) => {
                this.log.debug('pending row found...uploading', pending);
                const {id, ...data} = pending;
                const created = await this.service.create(data);
                this.log.debug('row saved', created);

                const local = await this.db.create({
                    ...created,
                    __status: 'saved',
                }, {dataStoreName: this.dataStoreName});
                await this.db.remove(pending.id, {dataStoreName: this.dataStoreName});

                this.log.debug('local db updated', local);

            }));
        } else {
            this.log.debug('app is offline, nothing to do');
        }
    }

    find(params: Params){
        if(this.online){
            return this.service.find(params);
        }
        return this.api.db.find({
            ...params,
            dataStoreName: this.dataStoreName,
        });
    }

    get(id: Id, params: Params){
        if(this.online){
            return this.service.get(id, params);
        }

        return this.api.db.get(id, {
            ...params,
            dataStoreName: this.dataStoreName,
        });
    }

    create(data: IData|IData[], params: Params){
        const row = Array.isArray(data) ? data[0] : data;

        // use offline method if a draft state
        if(row.__status !== 'draft'){
            if(this.online){
                return this.service.create(data as any, params);
            }
        }

        return this.api.db.create( {
            __status: 'pending',
            ...data,
        }, {
            ...params,
            dataStoreName: this.dataStoreName,
        });
    }

    patch(id: Id, data: IData, params: Params){
        if(this.online){
            return this.service.patch(id, data as any, params);
        } else {
            throw new Error('cannot patch data while offline');
        }

        // return this.api.db.patch(id, data, {
        //     ...params,
        //     dataStoreName: this.dataStoreName,
        // });
    }

    update(id: Id, data: IData, params: Params){
        if(this.online){
            return this.service.update(id, data as any, params);
        }else {
            throw new Error('cannot update data while offline');
        }

        // return this.api.db.update(id, data, {
        //     ...params,
        //     dataStoreName: this.dataStoreName,
        // });
    }

    remove(id, params: Params){
        if(this.online){
            return this.service.remove(id, params);
        } else {
            throw new Error('cannot remove data while offline');
        }

        // return this.api.db.remove(id, {
        //     ...params,
        //     dataStoreName: this.dataStoreName,
        // });
    }

    hooks(hooks){
        this.service.hooks(hooks);
    }

    on(evt: string, handler: () => unknown){
        this.service.on(evt, handler);
    }

    off(evt: string, handler: () => unknown){
        this.service.off(evt, handler);
    }

    private getStoreName(path: string): DataStoreOptions{
        const serviceParts = path.split('/');
        const storeName = camelCase( serviceParts[serviceParts.length - 1] );
        return storeName as DataStoreOptions;
    }
}
