import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';

/**
 * Converts GeoJSON to native openlayers feature objects
 */
export function toFeatures(geoJsonFeatures: any[], dataProjection = 'EPSG:4326', featureProjection = 'EPSG:3857'): Feature<Geometry>[] {
    const geojson = new GeoJSON();
    return geojson.readFeatures({
        type: 'FeatureCollection',
        features: geoJsonFeatures,
    }, { dataProjection, featureProjection });
}

/**
 *
 * Converts Openlayers feature objects to GeoJSON Feature Collections
 */
export function toGeoJSONFeatures(features: Feature<Geometry>[], dataProjection = 'EPSG:4326', featureProjection = 'EPSG:3857'): Record<string, any> {
    const geojson = new GeoJSON();
    return geojson.writeFeaturesObject(features, { dataProjection, featureProjection });
}

/**
 *
 * Converts an openlayers feature to a GeoJSON feature
 */
export function toGeoJSONFeature(feature: Feature<Geometry>, dataProjection = 'EPSG:4326', featureProjection = 'EPSG:3857'): Record<string, any> {
    const geojson = new GeoJSON();
    return geojson.writeFeatureObject(feature, { dataProjection, featureProjection });
}
