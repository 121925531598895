import { configure } from 'aurelia-event-aggregator';
import { LogManager } from 'aurelia-framework';
const log = LogManager.getLogger('dfp:config');

export interface IAppConfig {
}

const defaults = {
    API_HOST: '',
    TRANSPORT: 'socketio',
    MAPBOX_TOKEN: 'xxx',
    FORM_HOST: 'http://localhost:8005',
    SENTRY_DSN: 'https://08c76cf81f6141d48f3af243cb4bd7b6@o1297517.ingest.sentry.io/6526206',
    SENTRY_RELEASE_VERSION: 'dev',
    SENTRY_ENVIRONMENT: 'development',

    UNLEASH_API_URL: 'http://localhost:3000/proxy',
    UNLEASH_ENVIRONMENT: 'development',
    UNLEASH_CLIENT_KEY: 'secret',
};

export interface AppConfig {
    API_HOST: string;
    TRANSPORT: string;
    MAPBOX_TOKEN: string;
    FORM_HOST: string;
    SENTRY_DSN: string;
    SENTRY_RELEASE_VERSION: string;
    SENTRY_ENVIRONMENT: string;

    UNLEASH_API_URL: string;
    UNLEASH_ENVIRONMENT: string;
    UNLEASH_CLIENT_KEY: string;
}

export class AppConfig {

    async initialize(config?: Partial<AppConfig>){

        const configUri = `${document.baseURI.replace(/\/$/, '')}/config.json`;
        const staticConfig = await fetch(configUri)
            .then((res) => res.json())
            .catch((e) => ({}));

        let localStorageConfig;
        try {
            localStorageConfig = JSON.parse(localStorage.getItem('appConfig')) as IAppConfig;
        } catch (e) {
            log.debug('empty appConfig in localStorage');
            const localStorageConfig = {};
        }

        // this will likely fail but lets us set local storage overrides for testing things
        let appConfigOverride;
        try {
            appConfigOverride = JSON.parse(localStorage.getItem('appConfigOverride'));
        } catch (e) {
            log.debug('empty appConfigOverride in localStorage');
            appConfigOverride = {};
        }

        Object.assign(this, {
            ...defaults,
            ...localStorageConfig,
            ...config,
            ...staticConfig,
            ...appConfigOverride,
        });

        localStorage.setItem('appConfig', JSON.stringify(this));
        log.info('config loaded', this);
    }
}
