import { AssetGeometryType } from '@wsb_dev/datafi-shared/lib/types';

export interface IOptions {
    type: AssetGeometryType;
}

export interface IGetPointsOptions extends IOptions {
    geom: any;
}

export interface ICreateOptions extends IOptions {
    points?: number[][]
}

export class GeoJSONService {

    getPoints(opt: IGetPointsOptions): number[][] {

        switch(opt.type){
        case 'point':
            return [opt.geom.coordinates];

        case 'line':
            return opt.geom.coordinates;

        case 'polygon':
            return opt.geom.coordinates[0];
        }
    }

    create(opt: ICreateOptions){
        switch(opt.type){
        case 'point':
            return {
                type: 'Point',
                coordinates: opt.points[0],
            };

        case 'line':
            return {
                type: 'LineString',
                coordinates: opt.points,
            };

        case 'polygon':
            return {
                type: 'Polygon',
                coordinates: [opt.points],
            };
        }
    }
}
