import { ProgramAssetType, ProgramSurvey, Project } from '@wsb_dev/datafi-shared/lib/types';
import { inject } from 'aurelia-dependency-injection';
import { ActiveProgram } from '../util/ActiveProgram';

@inject(
    ActiveProgram,
)
/**
 * A shared service to store actively selected surveys, projects, and assets.
 * Used to provide context to the breadcrumb links
 */
export class ProjectBreadcrumbsService {
    /**
     * The currently selected survey item.
     * Used when the user clicks on a survey item from the survey list
     */
    survey?: ProgramSurvey;
    /**
     * The currently selected project.
     * Used when the user clicks on a project from the project list.
     */
    project?: Project;
    /**
     * Actively selected asset type - used when a user clicks on
     * an item from the asset type list.
     */
    assetType?: ProgramAssetType;

    constructor(private program: ActiveProgram){}
}
