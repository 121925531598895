import { DateValueConverter } from './date-format';
import { NumberValueConverter } from './number';
import { isValid } from '../../util/dates/isValid';

/**
 * Converts a value into a user friendly display.
 *
 * Tries with numbers, dates, and strings
 */
export class UnknownValueConverter {
    toView(value, format?: string) {
        if (!isNaN(value)) {
            return NumberValueConverter.prototype.toView(value, format);
        }

        if (isValid(value)) {
            return DateValueConverter.prototype.toView(value, format || 'MM-DD-YYYY');
        }

        return value;
    }

}
