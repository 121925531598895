import feathers, { Application, HookContext } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import restClient from '@feathersjs/rest-client';
import { omitHiddenProps } from './hooks/omitHiddenProps';
import { log as logHook } from './hooks/log';
import omit from 'lodash.omit';
import { AppConfig } from '../../services/util/AppConfig';
import { convertJsonQuery } from './hooks/convertJsonQuery';
import { LogManager } from 'aurelia-framework';
const log = LogManager.getLogger('dfp:actions');

export type TransportType = 'rest' | 'socketio';
export interface AppOptions {
    storageKey?: string;
}

export function createApp(config: Partial<AppConfig>, opt?:AppOptions ): Application{

    opt = {
        storageKey: 'auth',
        ...opt,
    };

    const app = feathers();

    if (config.TRANSPORT === 'rest') {
        app.configure(restClient(config.API_HOST).fetch(global.fetch));
    } else {
        const socket = io(config.API_HOST, {
            transports: [
                'websocket',
                'polling',
            ],
        });
        app.configure(socketio(socket, { timeout: 500000 }));
    }

    app.configure(auth({
        storageKey: opt.storageKey,
    }));

    app.hooks({
        before: {
            // UNCOMMENT this to log all api requests
            all: [
                logHook('params.query', 'data'),
                convertJsonQuery,
            ],
            create: [
                omitHiddenProps('data'),
                (context) => {
                    // TODO handle arrays
                    if (Array.isArray(context.data)) {
                        return;
                    }

                    // omit processing of files
                    if (context.data instanceof File) {
                        return;
                    }

                    Object.assign(context.data, omit(context.data, ['id']));
                },
            ],
            patch: [
                omitHiddenProps('data'),
            ],
            update: [
                omitHiddenProps('data'),
            ],
        },
        error: {
            all: [logHook('error')],
        },
    });

    Object.setPrototypeOf(app, Object);

    return app;
}
